import gql from "graphql-tag";
import PreStayPublicFragment from "../fragment/PreStayPublicFragment";

export default gql`

  ${PreStayPublicFragment}

  mutation preStayConfirmByIdAndPublicId($id: String!, $publicId: String!, $input: PreStayContentInput!) {
    preStay: preStayConfirmByIdAndPublicId(id: $id, publicId: $publicId, input: $input) {
      ...PreStayPublicFragment
    }
  }

`