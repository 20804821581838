import { useMutation } from "@apollo/react-hooks"
import confirmationListEventGql from "graphql/PMS/Confirmation/query/confirmationListEvent.gql"
import reservationAddEventGql from "graphql/PMS/Reservation/mutation/reservationAddEvent.gql"

import { formatErrorResponseForJoi } from "helpers/Apollo"
import { Event, EventInput } from "model"
import React, { useState } from "react"
import { EventForm } from "../Form/EventForm"

type EventAddByReservationProps = {
  reservationId: string,
  hotelId: string,
  date?: Date,
  onDone?: (event: Event) => void,
}

export const EventAddByReservation = ({ reservationId, hotelId, date, onDone }: EventAddByReservationProps) => {

  const [errors, updateErrors] = useState<any>()

  const [mutate] = useMutation(reservationAddEventGql, {
    refetchQueries: [{
      query: confirmationListEventGql,
      variables: {
        id: reservationId,
      }
    }]
  })

  const [event, updateEvent] = useState<Partial<EventInput>>({
    utcDate: date,
    source: {
      nbPeople: 2,
    },
  })

  const onSave = () => {
    mutate({
      variables: {
        id: reservationId,
        input: event,
      }
    })
      .then((response) => {
        onDone && onDone(response?.data?.event)
      })
      .catch((e) => {
        updateErrors(formatErrorResponseForJoi(e))
      })
  }


  return <div>
    <EventForm event={event} errors={errors} hotelId={hotelId} onChange={event => {
      updateEvent(event)
    }} />
    <div className="mt-4">
      <button className="btn btn-sm btn-primary" onClick={() => onSave()}>Enregistrer</button>
    </div>
  </div>
}