import { useMutation } from "@apollo/react-hooks";
import preStayAnswerByIdAndPublicIdGql from "graphql/PMS/Stay/Pre/mutation/preStayAnswerByIdAndPublicId.gql";
import { formatErrorResponseForJoi } from "helpers/Apollo";
import { PreStay, Reservation, Hotel, PreStayContentInput, PreStayContent } from "model"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { Element, Link } from "react-scroll";
import { ArrivalAnswer } from "../Arrival/ArrivalAnswer";
import { PreCheckinAnswer } from "../Checkin/PreCheckinAnswer";
import { ServiceAnswer } from "../Service/ServiceAnswer";
import { OptionsAnswer } from "../Options/OptionsAnswer";

import "./style.scss"
import { Modal } from "@zipou/front_tools";
import preStayConfirmByIdAndPublicIdGql from "graphql/PMS/Stay/Pre/mutation/preStayConfirmByIdAndPublicId.gql";

type PreStayAnswerProps = {
  preStay: PreStay,
  reservation: Reservation,
  hotel: Hotel,
}



let timeout: NodeJS.Timeout;


export const PreStayAnswer = ({ preStay, reservation, hotel }: PreStayAnswerProps) => {

  const getInput = (content: PreStayContent): PreStayContentInput => {

    const Checkin = preStay?.Checkin

    return {
      ...content,
      checkin: Checkin,
    }
  }

  const { t } = useTranslation('translation', { keyPrefix: 'prestay' });

  const [preStayContent, updatePreStayContent] = useState<PreStayContentInput>(getInput(preStay?.preStayContent))
  const [errors, updateErrors] = useState<any>()
  const [message, updateMessage] = useState<any>()

  const [mutate] = useMutation(preStayAnswerByIdAndPublicIdGql)
  const [mutateConfirm] = useMutation(preStayConfirmByIdAndPublicIdGql)



  const themeColor = `#${preStay?.Hotel?.themeColor}`

  useEffect(() => {

    if (!preStayContent?.checkin?.phone && preStay?.Reservation?.Contact?.isPhone1Valid) {
      updatePreStayContent((prestaycontent) => ({
        ...prestaycontent,
        checkin: {
          ...(prestaycontent?.checkin || {}),
          phone: preStay?.Reservation?.Contact?.phone1
        }
      }))
    }

    if (!preStayContent?.checkin?.name && preStay?.Reservation?.Contact?.lastName) {
      updatePreStayContent((prestaycontent) => ({
        ...prestaycontent,
        checkin: {
          ...prestaycontent?.checkin,
          name: reservation?.Contact?.lastName
        }
      }))
    }

    if (!preStayContent?.checkin?.mail && preStay?.Reservation?.Contact?.isEmailValid) {

      updatePreStayContent(prestaycontent => ({
        ...prestaycontent,
        checkin: {
          ...prestaycontent?.checkin,
          mail: reservation?.Contact?.email,
        }
      }))
    }

    if (!preStayContent?.checkin?.address && preStay?.Reservation?.Contact?.address1) {

      updatePreStayContent(prestaycontent => ({
        ...prestaycontent,
        checkin: {
          ...prestaycontent?.checkin,
          address: reservation?.Contact?.address1,
        }
      }))
    }

    if (!preStayContent?.checkin?.zip && preStay?.Reservation?.Contact?.zipcode) {

      updatePreStayContent(prestaycontent => ({
        ...prestaycontent,
        checkin: {
          ...prestaycontent?.checkin,
          zip: reservation?.Contact?.zipcode,
        }
      }))
    }

    if (!preStayContent?.checkin?.country && preStay?.Reservation?.Contact?.country) {

      updatePreStayContent(prestaycontent => ({
        ...prestaycontent,
        checkin: {
          ...prestaycontent?.checkin,
          country: reservation?.Contact?.country,
        }
      }))
    }
    if (!preStayContent?.checkin?.city && preStay?.Reservation?.Contact?.city) {

      updatePreStayContent(prestaycontent => ({
        ...prestaycontent,
        checkin: {
          ...prestaycontent?.checkin,
          city: reservation?.Contact?.city,
        }
      }))
    }

  }, [reservation, preStay])


  const onChangeWithTempo = (preStayContent: PreStayContentInput) => {
    updatePreStayContent(preStayContent)
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      onSave(preStayContent);
    }, 500);
  };

  const onChange = (preStayContent: PreStayContentInput) => {
    updatePreStayContent(preStayContent)
    onSave(preStayContent)
  }

  const filterInput = (preStayContent: PreStayContentInput) => {
    return {
      // ...input,
      arrival: {
        // arrivalMinutes: preStay?.preStayContent?.arrival?.arrivalMinutes,
        // arrivalHours: preStay?.preStayContent?.arrival?.arrivalHours,
        // charging: preStay?.preStayContent?.arrival?.charging,
        // parking: preStay?.preStayContent?.arrival?.parking,
        // transfert: preStay?.preStayContent?.arrival?.transfert,
        // comment: preStay?.preStayContent?.arrival?.comment,
        // transfertData: preStay?.preStayContent?.arrival?.transfertData,
        arrivalMinutes: preStayContent?.arrival?.arrivalMinutes,
        arrivalHours: preStayContent?.arrival?.arrivalHours,
        charging: preStayContent?.arrival?.charging,
        parking: preStayContent?.arrival?.parking,
        transfert: preStayContent?.arrival?.transfert,
        comment: preStayContent?.arrival?.comment,
        transfertData: preStayContent?.arrival?.transfertData,
      },
      checkin: {
        name: preStayContent?.checkin?.name,
        phone: preStayContent?.checkin?.phone,
        mail: preStayContent?.checkin?.mail,
        // idNumber: preStay?.preStayContent?.checkin?.idNumber,
        // expiration: preStay?.preStayContent?.checkin?.expiration,
        // issue: preStay?.preStayContent?.checkin?.issue,
        address: preStayContent?.checkin?.address,
        zip: preStayContent?.checkin?.zip,
        city: preStayContent?.checkin?.city,
        country: preStayContent?.checkin?.country,
      },
      optionsSelected: preStayContent?.optionsSelected?.map(opt => ({ optionId: opt?.optionId, quantity: opt?.quantity })),
    }
  }

  const onConfirm = (preStayContent: PreStayContentInput) => {

    const input = filterInput(preStayContent)

    return mutateConfirm({
      variables: {
        id: preStay?.id,
        publicId: preStay?.publicId,
        input,
      }
    })
      .then(() => {
        updateMessage(true)
      })
      .catch((e: any) => {
        updateErrors(formatErrorResponseForJoi(e))
      })

  }

  const onSave = (preStayContent: PreStayContentInput) => {

    const input = filterInput(preStayContent)

    return mutate({
      variables: {
        id: preStay?.id,
        publicId: preStay?.publicId,
        input,
      }
    })

  }

  const hasService = preStay?.serviceList?.length > 0
  const hasOptions = preStay?.optionList?.length > 0

  const menuList = [
    {
      key: "precheckin",
      offset: -190,
      label: t("title.checkin"),
    },
    {
      key: "arrival",
      offset: -120,
      label: t("title.arrival"),
    },
    ...(hasService ? [{
      key: "stay",
      offset: -120,
      label: t("title.stay"),
    }] : []),
    ...(hasOptions ? [{
      key: "options",
      offset: -120,
      label: t("title.service"),
    }] : []),
  ]


  return <div className="pre-stay-container container" style={{ "--theme-color": themeColor } as React.CSSProperties} >
    {/* {message && <div className='alert alert-success' style={{position: "sticky", top: 10, zIndex: 3000, padding: 30}}>{t("saved")}</div>} */}
    {errors && <div className='alert alert-danger' style={{ position: "sticky", top: 10, zIndex: 3000, padding: 30 }}>Erreur dans le formulaire, veuillez recommencer.</div>}
    <Modal display={!!message} onClose={() => updateMessage(null)}>
      {t("saved")}
    </Modal>
    <div className="row  bg-white">
      <div className="col-3 justify-content-left align-items-start  bg-white d-none d-lg-flex" style={{ paddingTop: 100 }}>

        <div className=" bg-white pl-1 horizontal-separator" style={{ position: "sticky", top: 200, minWidth: 212 }}>
          <div className="title-map-container" style={{ position: "sticky", top: 200 }}>

            {menuList?.map((menuItem, index) => {
              return <Link key={`menu_${index}`} offset={menuItem?.offset} activeClass="title focused" className={menuItem?.key} to={menuItem?.key} spy={true} smooth={true} duration={500} >
                <div className="title">
                  <h3>0{index + 1}</h3>
                  <h2 style={{ whiteSpace: "nowrap", textTransform: "uppercase" }}>{menuItem?.label}</h2>
                </div>
              </Link>
            })}

          </div>
        </div>


      </div>
      <div className="col-12 col-lg-9 bg-white">

        <Element id="precheckin" name="precheckin">
          <div className="pt-5 pb-5  bg-white">
            <div className="bg-white">
              <div className="p-3">
                <PreCheckinAnswer preStayContent={preStayContent} onChange={(datas: PreStayContentInput) => {
                  onChangeWithTempo({
                    ...preStayContent,
                    ...datas,
                  })
                }} />
              </div>
            </div>
          </div>
        </Element>

        <Element id="arrival" name="arrival">
          <div className="pt-5 pb-5 bg-white">
            <div className="bg-white">
              <div className="p-3">
                <ArrivalAnswer hotel={hotel} preStayContent={preStayContent} onChange={(input: PreStayContent) => {
                  onChangeWithTempo({
                    ...preStayContent,
                    ...input,
                  })
                }} />
              </div>
            </div>
          </div>
        </Element>

        {hasService && <Element id="stay" name="stay">
          <div className="pt-5 pb-5">
            <div className="bg-white">
              <div className="p-3">
                <ServiceAnswer preStay={preStay} reservation={reservation} hotel={hotel} serviceList={preStay?.serviceList} onChange={() => {
                  onSave(preStayContent)
                }} />
              </div>
            </div>
          </div>
        </Element>}

        {hasOptions && <Element id="options" name="options">
          <div className="pt-5 pb-5">
            <div className="bg-white">
              <div className="p-3">
                <OptionsAnswer preStayContent={preStayContent} optionList={preStay?.optionList} onChange={(datas: any) => {
                  onChangeWithTempo({
                    ...preStay,
                    ...datas,
                  })
                }} />
              </div>
            </div>
          </div>
        </Element>}

        <div className="" style={{ marginTop: 150 }}></div>


        <button className="btn btn-lg btn-primary" onClick={() => onConfirm(preStayContent)}>{t("envoyer")}</button>

        <div className="" style={{ marginTop: 30 }}></div>

      </div>
    </div>
  </div >
}