import { useQuery } from "@apollo/react-hooks"
import { RoomTypeDisplay } from "components/front/PMS/Room/Type/Display/RoomTypeDisplay"
import roomTypeGetGql from "graphql/PMS/Room/Type/query/roomTypeGet.gql"
import { RoomType } from "model"
import React from "react"

type RoomTypeDisplayProps = {
  roomTypeId: string,
}

export const BookingEngineRoomTypeDisplay = ({ roomTypeId }: RoomTypeDisplayProps) => {

  const { data } = useQuery<{ roomType: RoomType }>(roomTypeGetGql, {
    variables: {
      id: roomTypeId
    }
  })

  const roomType = data?.roomType

  return <div>
    <RoomTypeDisplay roomType={roomType} />
  </div>
}