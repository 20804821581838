import gql from "graphql-tag";
import RoomTypeAvailableFragmentGql from "../RoomTypeAvailable/fragment/RoomTypeAvailableFragment.gql";

export default gql`

  ${RoomTypeAvailableFragmentGql}

  query bookingEngineRoomTypeAvailable($hotelId: String!, $input: BookingEngineRoomTypeAvailableInput!) {
    roomTypeAvailable: bookingEngineRoomTypeAvailable(hotelId: $hotelId, input: $input) {
      ...RoomTypeAvailableFragment
    }
  }

`