import gql from "graphql-tag";
import ContactFragment from "../fragment/ContactFragment";

export default gql`

${ContactFragment}

mutation contactUpdate($id: String!, $input: ContactUpdateInput!) {
  contact: contactUpdate(id: $id, input: $input) {
    ...ContactFragment
  }
}

`