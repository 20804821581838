import { ApolloProvider } from "@apollo/react-hooks";
import { tokenContext } from "context/tokenContext";
import { getClient } from "helpers/Apollo";
import { getToken, saveToken } from "helpers/Cookies";
import { LoginToken } from "model";
import React, { useState } from "react"
import { Router } from "routes";


import "static/css/common.scss"
import "static/css/icons.scss"

const App = () => {


  const [token, updateToken] = useState<string>(getToken())
  const [apolloClient, updateApolloClient] = useState(getClient(token))

  const onTokenChange = (loginToken: LoginToken) => {
    console.log("onTokenChange", loginToken)
    saveToken(loginToken.token)
    updateToken(token)
    updateApolloClient(getClient(token))
  }

  return <div>
    <tokenContext.Provider value={{ token, updateToken: onTokenChange }}>
      <ApolloProvider client={apolloClient}>
        <Router />
      </ApolloProvider>
    </tokenContext.Provider>
  </div>
}

export default App;
