import { NumberInput, TextInput } from "@zipou/front_tools"
import { BookingEngineNbPeopleInput } from "model"
import React from "react"

export type NbPeopleFormProps = {
  nbPeople: BookingEngineNbPeopleInput,
  onChange: (nbPeople: BookingEngineNbPeopleInput) => void,
  errors: any,
}

export const NbPeopleForm = ({ nbPeople, errors, onChange }: NbPeopleFormProps) => {

  return <div>
    <NumberInput id="nbAdults" errors={errors} value={`${nbPeople.nbAdults || ""}`} label="Adultes" onChange={(value) => onChange({ ...nbPeople, nbAdults: Number(value) })} />
    <NumberInput id="nbChildren" errors={errors} value={`${nbPeople.nbChildren || ""}`} label="Enfants" onChange={(value) => onChange({ ...nbPeople, nbChildren: Number(value) })} />
    <NumberInput id="nbSpecial" errors={errors} value={`${nbPeople.nbSpecial || ""}`} label="Spécial" onChange={(value) => onChange({ ...nbPeople, nbSpecial: Number(value) })} />
  </div>

}