import React from "react"
import { PreStayStatusEnum } from "model"

export type PreStayStatusProps = {
  status: PreStayStatusEnum
}

export const PreStayStatus = (props: PreStayStatusProps) => {


  switch (props?.status) {

    case PreStayStatusEnum.PRE_STAY_STATUS_INITIAL: {
      return <span className="badge badge-sm badge-secondary">EN ATTENTE</span>
    }

    case PreStayStatusEnum.PRE_STAY_STATUS_DELETED: {
      return <span className="badge badge-sm badge-danger">SUPPRIME</span>
    }

    case PreStayStatusEnum.PRE_STAY_STATUS_DELIVERED: {
      return <span className="badge badge-sm badge-success">DELIVRE</span>
    }

    case PreStayStatusEnum.PRE_STAY_STATUS_SENT: {
      return <span className="badge badge-sm badge-dark">ENVOYE</span>
    }

    case PreStayStatusEnum.PRE_STAY_STATUS_ERROR: {
      return <span className="badge badge-sm badge-danger">ERREUR</span>
    }

    case PreStayStatusEnum.PRE_STAY_STATUS_READ: {
      return <span className="badge badge-sm badge-success">LU</span>
    }

    case PreStayStatusEnum.PRE_STAY_STATUS_TO_SEND: {
      return <span className="badge badge-sm badge-secondary">EN ATTENTE D'ENVOI</span>
    }

    default: {
      return <span className="badge badge-sm badge-dard">{props?.status}</span>
    }

  }


}