import gql from "graphql-tag";

export default gql`

  query bookingEngineBestRate($hotelId: String!, $date: DateTime!) {
    bestRate: bookingEngineBestRate(hotelId: $hotelId, date: $date) {
      id
      valueIncTax
    }
  }

`