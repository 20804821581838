import { DateInput } from "components/common/Form/DateInput"
import { Service, ServiceTypeEnum, EventInput } from "model"
import React from "react"
import { useTranslation } from "react-i18next"

type EEventFormPreStayProps = {
  event: EventInput,
  service: Service,
  onChange: (vent: EventInput) => void,
}

export const EventFormPreStay = ({ event, service, onChange }: EEventFormPreStayProps) => {

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'prestay' });
  const isFrench = i18n.language === "fr"


  switch (service?.type) {
    case ServiceTypeEnum.TYPE_RESTAURANT: {
      return <div>
        <h1>{t("service.book_modal_title")}</h1>
        <h3>{isFrench ? service?.labelFr : service.labelEn}</h3>

        <div className="row d-flex justify-content-center">
          <div className="d-flex flex-column col-12 col-md-6">
            <span className="text-left pl-2 pb-1 text-uppercase">{t("service.book_date")}</span>
            <DateInput value={event?.utcDate?.getTime()} onChange={(v, d) => {
              onChange({
                ...event,
                utcDate: d,
                source: {
                  ...(event.source || {}),
                  date: v,
                  utcDate: v,
                }
              })
            }} />
          </div>
        </div>
        <div className="row d-flex justify-content-center">

          <div className="d-flex flex-column col-12 col-md-3">
            <span className="text-left pl-2 pb-1 text-uppercase">{t("service.book_hours")}</span>
            <div className="input-group">
              <select className="form-control" value={event?.source?.hour || ""} onChange={(e) => {
                const newDate = new Date(new Date(event?.utcDate)?.setHours(parseInt(e.target.value)))
                console.log("SETTING HOUSR", newDate)

                onChange({
                  ...event,
                  utcDate: newDate,
                  source: {
                    ...(event.source || {}),
                    hour: e.target.value,
                  }
                })
              }}>
                {/* <option value="0">0H</option>
                <option value="1">1H</option>
                <option value="2">2H</option>
                <option value="3">3H</option>
                <option value="4">4H</option>
                <option value="5">5H</option>
                <option value="6">6H</option>
                <option value="7">7H</option>
                <option value="8">8H</option>
                <option value="9">9H</option>
                <option value="10">10H</option> */}
                <option value="11">11H</option>
                <option value="12">12H</option>
                <option value="13">13H</option>
                <option value="14">14H</option>
                <option value="" disabled>-</option>
                {/* <option value="15">15H</option>
                <option value="16">16H</option>
                <option value="17">17H</option>
                <option value="18">18H</option> */}
                <option value="19">19H</option>
                <option value="20">20H</option>
                <option value="21">21H</option>
                <option value="22">22H</option>
                <option value="23">23H</option>
              </select>
            </div>
          </div>
          <div className="d-flex flex-column col-12 col-md-3">
            <span className="text-left pl-2 pb-1 text-uppercase">{t("service.book_minutes")}</span>
            <div className="input-group">
              <select className="form-control" value={event?.source?.minutes || ""} onChange={(e) => {
                const newDate = new Date(new Date(event?.utcDate)?.setMinutes(parseInt(e.target.value)))
                console.log("SETTING MINUTES", newDate)

                onChange({
                  ...event,
                  utcDate: newDate,
                  source: {
                    ...(event.source || {}),
                    minutes: e.target.value,
                  }
                })
              }}>
                <option value="" disabled>-</option>
                <option value="0">0</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
              </select>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="d-flex flex-column col-12 col-md-4">
            <span className="text-left pl-2 pb-1 text-uppercase">{t("service.book_nbPeople")}</span>
            <div className="input-group">
              {event?.source?.nbPeople > 1 && <span className="input-group-text" style={{ cursor: "pointer" }} onClick={() => {
                onChange({
                  ...event,
                  source: {
                    ...(event.source || {}),
                    nbPeople: (event?.source?.nbPeople) - 1
                  }
                })
              }}>
                -
              </span>}
              <input type="number" className="form-control" value={event?.source?.nbPeople} />
              <span className="input-group-text " style={{ cursor: "pointer" }} onClick={() => {
                onChange({
                  ...event,
                  source: {
                    ...(event.source || {}),
                    nbPeople: (event?.source?.nbPeople) + 1
                  }
                })
              }}>
                +
              </span>
            </div>
          </div>
        </div>
      </div>
    }

    case ServiceTypeEnum.TYPE_SPA: {
      return <div>
        <h1>{t("service.book_modal_title")}</h1>
        <h3>{isFrench ? service?.labelFr : service.labelEn}</h3>

        <div className="row d-flex justify-content-center">
          <div className="d-flex flex-column col-12 col-md-6">
            <span className="text-left pl-2 pb-1 text-uppercase">{t("service.book_date")}</span>
            <DateInput value={event?.utcDate} onChange={(v, d) => {
              onChange({
                ...event,
                utcDate: new Date(d),
                source: {
                  ...(event.source || {}),
                  date: v,
                  utcDate: v,
                }
              })
            }} />
          </div>
        </div>
        <div className="row d-flex justify-content-center">

          <div className="d-flex flex-column col-12 col-md-3">
            <span className="text-left pl-2 pb-1 text-uppercase">{t("service.book_hours")}</span>
            <div className="input-group">
              <select className="form-control" value={event?.source?.hour || ""} onChange={(e) => {
                const newDate = new Date(new Date(event?.utcDate)?.setHours(parseInt(e.target.value)))

                onChange({
                  ...event,
                  utcDate: newDate,
                  source: {
                    ...(event.source || {}),
                    hour: e.target.value,
                  }
                })
              }}>
                {/* <option value="0">0H</option>
                <option value="1">1H</option>
                <option value="2">2H</option>
                <option value="3">3H</option>
                <option value="4">4H</option>
                <option value="5">5H</option>
                <option value="6">6H</option>
                <option value="7">7H</option>
                <option value="8">8H</option> */}
                <option value="9">9H</option>
                <option value="10">10H</option>
                <option value="11">11H</option>
                <option value="12">12H</option>
                <option value="" disabled>-</option>
                <option value="13">13H</option>
                <option value="14">14H</option>
                <option value="15">15H</option>
                <option value="16">16H</option>
                <option value="17">17H</option>
                <option value="18">18H</option>
                <option value="19">19H</option>
                {/* <option value="20">20H</option>
                <option value="21">21H</option>
                <option value="22">22H</option>
                <option value="23">23H</option> */}
              </select>
            </div>
          </div>
          <div className="d-flex flex-column col-12 col-md-3">
            <span className="text-left pl-2 pb-1 text-uppercase">{t("service.book_minutes")}</span>
            <div className="input-group">
              <select className="form-control" value={event?.source?.minutes || ""} onChange={(e) => {

                const newDate = new Date(new Date(event?.utcDate)?.setMinutes(parseInt(e.target.value)))
                console.log("newDate", newDate)

                onChange({
                  ...event,
                  utcDate: newDate,
                  source: {
                    ...(event.source || {}),
                    minutes: e.target.value,
                  }
                })
              }}>
                <option value="" disabled>-</option>
                <option value="0">0</option>
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="45">45</option>
              </select>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="d-flex flex-column col-12 col-md-4">
            <span className="text-left pl-2 pb-1 text-uppercase">{t("service.duration")}</span>
            <div className="input-group">
              {event?.source?.duration > 1 && <span className="input-group-text" style={{ cursor: "pointer" }} onClick={() => {
                onChange({
                  ...event,
                  source: {
                    ...(event.source || {}),
                    duration: (event?.source?.duration) - 1
                  }
                })
              }}>
                -
              </span>}
              <input type="number" className="form-control" value={event?.source?.duration} />
              <span className="input-group-text " style={{ cursor: "pointer" }} onClick={() => {
                onChange({
                  ...event,
                  source: {
                    ...(event.source || {}),
                    duration: (event?.source?.duration) + 1
                  }
                })
              }}>
                +
              </span>
            </div>
          </div>
        </div>
      </div >

    }

  }

  return <div>

  </div>
}