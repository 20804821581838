import gql from "graphql-tag";

export default gql`
  fragment PropositionConfigFragment on PropositionConfig {
    templateId
    dest
    demoMode
    illustrationUrl
    displayCancelCondition
    displayDiscountAmout

  }
`