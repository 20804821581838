import { useMutation, useQuery } from "@apollo/react-hooks"
import { DateInput } from "components/common/Form/DateInput"
import preStayAddEventGql from "graphql/PMS/Stay/Pre/mutation/preStayAddEvent.gql"
// import {formatErrorResponseForJoi} from "helpers/Apollo"
import { Service, ServiceTypeEnum, EventInput, PreStay, Event } from "model"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { EventFormPreStay } from "../Form/EventFormPreStay"
import preStayEditEventGql from "graphql/PMS/Stay/Pre/mutation/preStayEditEvent.gql"
import { get } from "react-scroll/modules/mixins/scroller"
import eventGetGql from "graphql/PLANNING/Event/query/eventGet.gql"
import eventGetByPrestayGql from "graphql/PLANNING/Event/query/eventGetByPrestay.gql"

type EventEditByPreStayProps = {
  eventId: String,
  preStay: PreStay,
  onDone?: (eventId: string) => void,
}

export const EventEditByPreStay = ({ eventId, preStay, onDone }: EventEditByPreStayProps) => {

  // const [errors, updateErrors] = useState<any>()

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'prestay' });
  const isFrench = i18n.language === "fr"

  const { data } = useQuery<{ event: Event }>(eventGetByPrestayGql, {
    variables: {
      id: preStay?.id,
      publicId: preStay?.publicId,
      eventId,
    },
    onCompleted: (data) => {
      updateInput(getInput(data?.event))
    }
  })

  const [mutate] = useMutation(preStayEditEventGql)

  const event = data?.event
  const service = event?.Service


  const getInput = (event: Event): EventInput => {
    return {
      utcDate: new Date(event.utcDate),
      serviceId: event.Service?.id,
      source: {
        ...event.source
      },
    }
  }

  const [input, updateInput] = useState<EventInput>()

  const onSave = () => {
    mutate({
      variables: {
        id: preStay?.id,
        publicId: preStay?.publicId,
        eventId: event?.id,
        input,
      }
    })
      .then((response) => {
        onDone && onDone(response?.data?.event.id)
      })
      .catch((e) => {
        // updateErrors(formatErrorResponseForJoi(e))
      })
  }

  return <div>
    <EventFormPreStay service={service} onChange={e => updateInput(e)} event={input} />
    <div className="mt-4">
      <button className="btn btn-primary" onClick={() => onSave()}>{t("save")}</button>
    </div>
  </div>
}