import React, { useContext, useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import { Pagination } from "components/common/Navigation/Pagination/Pagination"

// import './style.scss'
import { ReservationFullDetails } from "components/front/PMS/Reservation/Details/ReservationFullDetails"
import { DateTimeFormat } from "components/common/Format/DateTimeFormat"
import { HotelBadge } from "components/front/PMS/Hotel/Badge/HotelBadge"
import { userContext } from "context/User"
import { ContactEditButton } from "components/front/PMS/Contact/Widget/ContactEditButton"
// import {OverlayTrigger, Tooltip} from "react-bootstrap"

import { CursorInput, PreStay, PreStayListInput, PreStayListResult, PreStayResponseStatusEnum, PreStayStatusEnum } from "model"
import { PreStayStatus } from "../Status/PreStayStatus"
import preStayListByCursorAndInputGql from "graphql/PMS/Stay/Pre/query/preStayListByCursorAndInput.gql"
import { Link, Route, useHistory } from "react-router-dom"
import { URL_PRE_STAY_EDIT, URL_PRE_STAY_LIST, URL_PUBLIC_PRE_STAY_ANSWER } from "constant/url"
import { PreStayView } from "../View/PreStayView"
import { IconEnvelope, IconTrash, Modal, TextInput } from "@zipou/front_tools"
import { PreStaySendStatus } from "../Status/PreStaySendStatus"
import { PreStayResponseStatus } from "../Status/PreStayResponseStatus"
import { PreStayPreview } from "../Preview/PreStayPreview"
import { PreStayStatusFilter } from "../Status/PreStayStatusFilter"

export type PreStayListProps = {
  focusViewId?: string,
}

export const PreStayList = (props: PreStayListProps) => {

  const history = useHistory()

  const limit = 20;

  const user = useContext(userContext)
  const [focusPreview, updateFocusPreview] = useState<PreStay | null>(null)
  const [reservationFocusId, updateReservationFocusId] = useState(null)
  const [focusPmsId, updateFocusPmsId] = useState(null)
  const [focusHotelIds, updateFocusHotelIds] = useState(null)

  const [pmsIdFilter, updatePmsIdFilter] = useState<string>()
  const [statusFilter, updateStatusFilter] = useState<PreStayStatusEnum[]>([PreStayStatusEnum.PRE_STAY_STATUS_INITIAL])
  const [focusStatus, updateFocusStatus] = useState<any>(false)
  const [hotelIdsFilter, updateHotelIdsFilter] = useState<any>(user?.hotels)

  const isMulti = user?.hotels?.length > 1

  const { data, refetch, loading } = useQuery<{ list: PreStayListResult }, { input: PreStayListInput, cursor: CursorInput }>(preStayListByCursorAndInputGql, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
    variables: {
      cursor: {
        page: 1,
        limit,
      },
      input: {
        orderField: "prestay.answeredDate",
        orderDirection: "DESC",
        status: statusFilter,
        reservationPmsId: pmsIdFilter,
        hotelIdsList: hotelIdsFilter || [],
      }
    }
  })


  const preStay = data?.list

  const { nodes, cursor } = preStay || {}
  const hasResults = nodes?.length > 0

  return <div className="confirmation-list">
    <Route path={URL_PRE_STAY_EDIT}>
      <Modal size="xl" display={true} title="Pre Stay" onClose={() => history.push(URL_PRE_STAY_LIST)}>
        <PreStayView preStayId={props?.focusViewId} />
      </Modal>
    </Route>

    {focusStatus && <Modal title="Statut" display={true} onClose={() => updateFocusStatus(false)}>
      <PreStayStatusFilter statusSelectedList={statusFilter} onChange={(statusSelectedList) => {
        updateStatusFilter(statusSelectedList)
      }} />
    </Modal>}
    {!!focusPreview && <Modal display onClose={() => updateFocusPreview(null)} >
      <PreStayPreview preStayId={focusPreview?.id} />
    </Modal>}
    <Modal display={!!focusHotelIds} onClose={() => updateFocusHotelIds(null)}>
      <button className="btn btn-sm btn-warning" onClick={() => updateHotelIdsFilter([])}>Clear All</button>
      <button className="btn btn-sm btn-primary" onClick={() => updateHotelIdsFilter(user?.Hotel?.map((h: any) => h.id))} style={{ marginLeft: 5 }}>Select All</button>
      <ul className="list-group">
        {user?.Hotel?.map((hotel: any) => {
          const isChecked = hotelIdsFilter?.includes(hotel?.id);
          return <li key={`hotel_filter_${hotel?.id}`} className="list-group-item" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <input type="checkbox" checked={isChecked} id={`cb_${hotel?.id}`} onChange={(e: any) => updateHotelIdsFilter(e.target.checked ? [...(hotelIdsFilter || []), hotel?.id] : hotelIdsFilter?.filter((el: any) => el !== hotel?.id))}></input>
            <label htmlFor={`cb_${hotel?.id}`}><HotelBadge hotel={hotel} /></label>
          </li>
        })}
      </ul>
    </Modal>
    <Modal display={!!reservationFocusId} onClose={() => updateReservationFocusId(null)}>
      <ReservationFullDetails reservationId={reservationFocusId} />
    </Modal>
    <Modal display={!!focusPmsId} onClose={() => updateFocusPmsId(null)}>
      <TextInput label="Numero de Résa" errors={null} value={pmsIdFilter} onChange={v => updatePmsIdFilter(v)} rightContent={<span onClick={() => {
        updateFocusPmsId(null);
        updatePmsIdFilter(null);
      }}><IconTrash /></span>} />
    </Modal>

    {(loading) && <div className="position-fixed" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div className="alert alert-warning">Chargement...</div>
    </div>}
    <div className="content">
      <table className="table table-stripped table-bordered">
        <thead>
          <tr className="bg-dark text-white">
            {isMulti && <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                updateFocusHotelIds(true)
              }}>
                Hotel
              </button>
            </th>}
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                updateFocusPmsId(true)
              }}>
                Numéro de résa
              </button>
            </th>
            <th className="text-white">Contact</th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                refetch({
                  ...data?.list?.cursor,
                  input: {
                    ...data?.list.input,
                    orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                    orderField: "confirmation.creationDate",
                  },
                })
              }}>
                Date de Création {data?.list?.input?.orderField === "confirmation.creationDate" && <span className={(data?.list?.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                refetch({
                  ...data?.list?.cursor,
                  input: {
                    ...data?.list.input,
                    orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                    orderField: "prestay.answeredDate",
                    hotelIdsList: hotelIdsFilter || [],
                  },
                })
              }}>
                Répondu le {data?.list?.input?.orderField === "prestay.answeredDate" && <span className={(data?.list?.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
                refetch({
                  ...data?.list?.cursor,
                  input: {
                    ...data?.list.input,
                    orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                    orderField: "prestay.sendDateTarget",
                    hotelIdsList: hotelIdsFilter || [],
                  },
                })
              }}>
                Envoi prévu le {data?.list?.input?.orderField === "prestay.sendDateTarget" && <span className={(data?.list?.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
              </button>
            </th>
            <th className="text-white">
              <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => updateFocusStatus(true)}>
                Statut <span className="badge badge-sm badge-dark">{statusFilter?.length}</span>
              </button>
            </th>
            <th className="text-white">Action</th>
          </tr>
        </thead>
        <tbody>
          {(!hasResults && !loading) && <tr>
            <td colSpan={isMulti ? 8 : 7}><div className="alert alert-primary">Rien à afficher</div></td>
          </tr>}
          {hasResults && nodes?.map((preStay: PreStay) => {
            const { id, Hotel, Reservation, ReservationGroup, Contact, creationDate, sendDateTarget, answeredDate, publicId } = preStay

            const isAnswered = preStay?.responseStatus === PreStayResponseStatusEnum.PRE_STAY_RESPONSE_STATUS_ANSWERED
            const isGroup = !!ReservationGroup
            const reservationGroupLinkList = ReservationGroup?.ReservationGroupLink
            const emailCanBeDisplayed = true

            return <tr key={`preStay${preStay?.id}`}>
              {isMulti && <td><HotelBadge hotel={Hotel} /></td>}
              {!isGroup && <td><button className="brn btn-sm" onClick={() => updateReservationFocusId(Reservation?.id)}>{Reservation?.pmsId}</button></td>}
              {isGroup && <td>{reservationGroupLinkList?.map((reservationGroupLink) => <button key={`resa_button_${reservationGroupLink?.id}`} className="brn btn-sm" style={{ marginLeft: 3 }} onClick={() => updateReservationFocusId(reservationGroupLink?.Reservation?.id)}>{reservationGroupLink?.Reservation?.pmsId}</button>)}</td>}
              <td>
                <ContactEditButton contact={Contact} />
              </td>
              <td>{creationDate && <DateTimeFormat value={new Date(creationDate)} />}</td>
              <td>{answeredDate && <DateTimeFormat value={new Date(answeredDate)} />}</td>
              <td>{sendDateTarget && <DateTimeFormat value={new Date(sendDateTarget)} />}</td>
              <td>
                <div>
                  <PreStayStatus status={preStay?.status as PreStayStatusEnum} />
                </div>
                <div>
                  <PreStaySendStatus preStay={preStay} />
                </div>
                <div>
                  <PreStayResponseStatus preStay={preStay} />
                </div>
              </td>
              <td className="">
                <div className="actions">
                  {/* <Link target={"_blank"} to={URL_PUBLIC_PRE_STAY_ANSWER.replace(":id", id).replace(":publicId", publicId)}>
                    <button className="btn btn-sm btn-warning ml-1">
                      <span className="icon-edit" />
                    </button>
                  </Link> */}
                  {isAnswered && <Link to={URL_PRE_STAY_EDIT.replace(":id", id)}>
                    <button className="btn btn-sm btn-warning ml-1">
                      <span className="icon-search" />
                    </button>
                  </Link>}
                  {(emailCanBeDisplayed) && <button className="btn btn-sm btn-secondary text-white ml-1" onClick={() => updateFocusPreview(preStay)}>
                    <IconEnvelope style={{ fontSize: 13, color: "white" }} />
                  </button>}
                </div>
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
    <div className="pagination-container">
      <Pagination cursor={cursor} onChange={(page: number) => {
        refetch({
          cursor: {
            page,
            limit,
          }
        })
      }} />
    </div>
  </div >
}