import { BookingEngine } from "components/front/PMS/BookingEngine/BookingEngine"
import React from "react"


export const BookingEngineRoute = () => {


  return <div>
    <BookingEngine hotelId="ac6bbd83-857a-11eb-87cd-de1c90642014" style={{
      "--primary-color": "#007bff",
    }} />
  </div>

}