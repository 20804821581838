import React, { useState } from "react"
import { ApolloCache, DefaultContext, DocumentNode, MutationFunctionOptions, OperationVariables, useMutation } from "@apollo/react-hooks"
import { Modal } from "@zipou/front_tools"

export type MutationWrapperProps = {
  mutation: DocumentNode,
  onDone: (response: any, error: any) => void,
  children: any,
}

export const MutationWrapper = (props: MutationWrapperProps) => {

  // const [optionsCached, updateOptionsCached] = useState()
  const [displayLogin, updateDisplayLogin] = useState(false)

  const [mutate, { loading }] = useMutation(props?.mutation)

  const mutateFunction = (options?: MutationFunctionOptions<any, OperationVariables, DefaultContext, ApolloCache<any>>) => {
    // updateOptionsCached(options)
    mutate(options)
      .then((response) => {
        updateDisplayLogin(false)
        props?.onDone(response, null)
      })
      .catch((error) => {
        if (error && error.message === "Access denied! You don't have permission for this action!") {
          updateDisplayLogin(true)
        } else {
          props?.onDone(null, error)
        }

      })
  }


  return <div>
    {displayLogin && <Modal display={true}>
      <h1>Connexion perdue...</h1>
      {/* <LoginForm onSuccess={() => {
        mutateFunction(optionsCached)
      }} /> */}
    </Modal>}
    {props.children(mutateFunction, loading)}
  </div>

}