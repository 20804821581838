import gql from "graphql-tag";

export default gql`

  fragment ConfirmationConfigPublicFragment on ConfirmationConfig {
    id
    hotelId
    dest
    channel
    type
    displayCancelCondition
  }

`