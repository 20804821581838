
import { TextInput } from "components/common/Form/TextInput"
import React, { useContext, useState } from "react"
// import {RoomTypeEdit} from "../../Room/Edit/RoomEdit"

import './style.scss'

import { NumberInput } from "components/common/Form/NumberInput"
import { BillingConfigForm } from "components/front/BILLING/Config/Form/BillingConfigForm"
import { BooleanInput } from "components/common/Form/BooleanInput"
import { Hotel, NotificationTypeEnum, SeasonalityInput, User, UserRoleEnum, File, HotelInput } from "model"
import { userContext } from "context/User"
import { PictureUploader } from "components/common/Form/PictureUploader/PictureUploader"
import { ColorInput } from "components/common/Form/ColorInput"
import { PostStayConfigForm } from "../../Stay/Post/Config/Form/PostStayConfigForm"
import { FileInput } from "components/front/APP/File/Input/FileInput"
import { FileList } from "components/front/APP/File/List/FileList"
import { IconEdit, IconTrash, IconWarningSign } from "@zipou/front_tools"
import { PhoneConfigForm } from "components/front/PHONE/Config/Form/PhoneConfigForm"
import { NetworkConfigForm } from "components/front/NETWORK/Config/Form/NetworkConfigForm"
import { PmsConfigForm } from "../../Config/Form/ConfigForm"
import { TripAdvisorConfigForm } from "../../Social/TripAdvisorConfig/Form/TripAdvisorConfigForm"
import { GoogleConfigForm } from "../../Social/GoogleConfig/Form/GoogleConfigForm"
import { URL_BACKOFFICE_HOTEL_LIST } from "constant/url"
import { Link } from "react-router-dom"
import { TemplateList } from "components/front/APP/Template/List/TemplateList"
import { ConfirmationConfigForm } from "../../Confirmation/Config/Form/ConfirmationConfigForm"
import { PropositionConfigForm } from "../../Proposition/Config/Form/PropositionConfigForm"
import { hotelContext } from "context/hotelContext"
import { PriceRateTypeList } from "../../PriceRate/Type/List/PriceRateTypeList"
import { OptionList } from "../../Option/List/OptionList"
import { RoomTypeList } from "../../Room/Type/List/RoomTypeList"
import { ServiceList } from "../../Service/List/ServiceList"
import { SeasonalityList } from "../../Seasonality/List/SeasonalityList"
import { PriceRateCancelConditionList } from "../../PriceRate/CancelCondition/List/PriceRateCancelConditionList"
import { HotelTokenListForm } from "../Token/List/HotelTokenListForm"
import { VideoConfigForm } from "components/front/VIDEO/Config/Form/VideoConfigForm"
import { RoomList } from "../../Room/List/RoomList"
import { DeviceList } from "components/front/APP/Device/List/DeviceList"
import { PreStayConfigForm } from "../../Stay/Pre/Config/Form/PreStayConfigForm"
import { BookingEngineConfigForm } from "../../BookingEngine/Config/Form/BookingEngineConfigForm"

type HotelFormProps = {
  hotel: HotelInput,
  errors: any,
  onChange: (hotel: HotelInput) => void,
}

enum FocusTypeEnum {
  GENERAL = "GENERAL",
  FILE = "FILE",
  BOOKING_ENGINE = "BOOKING_ENGINE",
  SEASONALITY = "SEASONALITY",
  POST_STAY = "POST_STAY",
  PRE_STAY = "PRE_STAY",
  CONFIRMATION = "CONFIRMATION",
  PROPOSITION = "PROPOSITION",
  PRICE_RATE = "PRICE_RATE",
  CANCEL_CONDITION = "CANCEL_CONDITION",
  ROOM = "ROOM",
  ROOM_TYPE = "ROOM_TYPE",
  OPTIONS = "OPTIONS",
  // "BILLING" = "BILLING",
  SERVICES = "SERVICES",
  INTERFACE = "INTERFACE",
  TEMPLATE = "TEMPLATE",
  VIDEO = "VIDEO",
  DEVICE = "DEVICE",
  BILLING = "BILLING",
}

export const HotelForm = (props: HotelFormProps) => {


  const [focus, updateFocus] = useState<FocusTypeEnum | null>()

  const onChange = (hotel: HotelInput) => {
    props?.onChange(hotel)
  }

  const hotel = props?.hotel;
  const errors = props?.errors;
  const hasErrors = errors && Object.keys(errors)?.length > 0

  const user = useContext<User>(userContext)
  const hotelFromContext = useContext<Hotel>(hotelContext)

  const hotelId = hotelFromContext?.id

  const isAdmin = user?.roles?.includes(UserRoleEnum.ROLE_ADMIN)
  const isConfig = isAdmin || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG)
  const hasGeneral = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_GENERAL)
  const hasConfirmation = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_CONFIRMATION)
  const hasProposition = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_PROPOSITION)
  const hasOptions = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_OPTIONS)
  const hasPriceRate = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_RATE_CODE)
  const hasServices = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_SERVICE)
  const hasCancelConditions = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_CANCEL_CONDITIONS)
  const hasRoomType = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_ROOM_TYPE)
  const hasConfigStay = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_STAY)
  const hasConfigPostStay = isAdmin || isConfig || hasConfigStay || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_POST_STAY)
  const hasConfigPreStay = isAdmin || isConfig || hasConfigStay || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_PRE_STAY)
  const hasFile = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_FILE)
  const hasVideo = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_VIDEO_CONFIG)
  const hasBilling = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_BILLING)
  const hasRoom = isAdmin || isConfig || user?.roles?.includes(UserRoleEnum.ROLE_CONFIG_ROOM)
  const hasPhoneConfig = isAdmin || isConfig
  const hasInterface = isAdmin || isConfig
  const hasDevice = isAdmin || isConfig
  const hasBookingEngineConfig = isAdmin

  const hasFocus = !!focus

  console.log("errors", errors)

  return <div className="hotel-edit-container">

    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className={"breadcrumb-item"}>
          Configuration
        </li>
        <li className={"breadcrumb-item"}>
          <Link to={URL_BACKOFFICE_HOTEL_LIST}>
            Liste des Hotels
          </Link>
        </li>
        <li className={hasFocus ? "breadcrumb-item" : "breadcrumb-item active"}>
          <a onClick={() => updateFocus(null)}>
            {/* <Link to={URL_BACKOFFICE_HOTEL_EDIT.replace(":hotelId", hotel?.id)}> */}
            {hotel?.configGeneral?.name}
            {/* </Link> */}
          </a>
        </li>
        {hasFocus && <li className={"breadcrumb-item active"}>
          {focus}
        </li>}
      </ol>
    </nav >
    {hasErrors && <div className="alert alert-danger" style={{ position: "sticky", top: 10, zIndex: 1000 }}>
      <h2>Erreur dans le formulaire</h2>
      <ul>
        {Object.keys(errors)?.map((key: string) => {
          // const value = errors[key]
          return <li>{key}</li>
        })}
      </ul>
    </div>}
    <h1>{hotel?.configGeneral?.name}</h1>
    {!hasFocus && <div>


      <div className="row">
        {hasGeneral && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Infos Générales
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.GENERAL)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasConfirmation && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Confirmation
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.CONFIRMATION)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasProposition && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Proposition
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.PROPOSITION)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}
        {(hasOptions && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Options
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.OPTIONS)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasPriceRate && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Code Tarifs
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.PRICE_RATE)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasRoom && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Chambre
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.ROOM)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasRoomType && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Type de Chambre
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.ROOM_TYPE)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasConfigPreStay && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header d-flex align-items-center text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Pré Stay <span className="ml-2">{errors?.configStay && <IconWarningSign />}</span>
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.PRE_STAY)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasConfigPostStay && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header d-flex align-items-center text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Post Stay <span className="ml-2">{errors?.configStay && <IconWarningSign />}</span>
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.POST_STAY)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {hasCancelConditions && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Conditions d'Annulation
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.CANCEL_CONDITION)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {hasServices && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Services
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.SERVICES)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasFile && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Fichiers
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.FILE)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasBookingEngineConfig && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Booking Engine
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.BOOKING_ENGINE)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasGeneral && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Saisonalité
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.SEASONALITY)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasGeneral && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Template
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.TEMPLATE)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasInterface && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Interfaces
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.INTERFACE)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasVideo && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Video Surveillance
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.VIDEO)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasBilling && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Paiement
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.BILLING)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

        {(hasDevice && hotelId) && <div className="col-12 col-sm-3">
          <div className="card m-1">
            <div className="card-header text-white" style={{ backgroundColor: `#${hotelFromContext?.themeColor || "000"}` }}>
              Device
            </div>
            <div className="card-body d-flex justify-content-center">
              <button className="btn btn-sm btn-dark" onClick={() => updateFocus(FocusTypeEnum.DEVICE)}>
                Editer <IconEdit />
              </button>
            </div>
          </div>
        </div>}

      </div>
    </div>}

    {hasFocus && <div>

      {focus === FocusTypeEnum.TEMPLATE && <div className="card">
        <div className="card-header text-white bg-dark">
          Template
        </div>
        <div className="card-body">
          <TemplateList hotelId={hotelId} />
        </div>
      </div>}

      {focus === FocusTypeEnum.BOOKING_ENGINE && <div className="card">
        <div className="card-header text-white bg-dark">
          Booking Engine
        </div>
        <div className="card-body">
          <BookingEngineConfigForm hotelId={hotelId} errors={errors} bookingEngineConfig={hotel?.bookingEngineConfig} onChange={(bec) => {
            onChange({
              ...hotel,
              bookingEngineConfig: bec,
            })
          }} />
        </div>
      </div>}

      {focus === FocusTypeEnum.BILLING && <div className="card">
        <div className="card-header text-white bg-dark">
          Paiement
        </div>
        <div className="card-body">
          <BillingConfigForm hotelId={hotelId} billingConfig={hotel?.configBilling} onChange={(billingConfig) => {
            onChange({
              ...hotel,
              configBilling: billingConfig,
            })
          }} />
        </div>
      </div>}

      {focus === FocusTypeEnum.ROOM && <div className="card">
        <div className="card-header text-white bg-dark">
          Room
        </div>
        <div className="card-body">
          <RoomList errors={errors?.configRoom} roomList={hotel?.configRoom} onChange={(configRoom) => {
            onChange({
              ...hotel,
              configRoom,
            })
          }} />
        </div>
      </div>}

      {focus === FocusTypeEnum.VIDEO && <div className="card">
        <div className="card-header text-white bg-dark">
          Video Surveillance
        </div>
        <div className="card-body">
          <VideoConfigForm errors={errors?.videoConfig} videoConfig={hotel?.videoConfig} onChange={(vConfig) => {
            onChange({
              ...hotel,
              videoConfig: vConfig,
            })
          }} />
        </div>
      </div>}


      {focus === FocusTypeEnum.INTERFACE && <div className="card">
        <div className="card-header text-white bg-dark">
          Interface
        </div>
        <div className="card-body">
          <div className="input-group">
            <span className="input-group-text">PMS</span>
            <div className="form-control" style={{ height: "100%" }}>
              <PmsConfigForm errors={errors?.pmsConfig} pmsConfig={hotel?.pmsConfig} onChange={pmsConfig => {
                onChange({
                  ...hotel,
                  pmsConfig,
                })
              }} />
            </div>
          </div>
          <div className="input-group">
            <span className="input-group-text">Réseau</span>
            <div className="form-control" style={{ height: "100%" }}>
              <NetworkConfigForm errors={errors?.networkConfig} networkConfig={hotel?.networkConfig} onChange={netConfig => {
                onChange({
                  ...hotel,
                  networkConfig: netConfig,
                })
              }} />
            </div>
          </div>
          <div className="input-group">
            <span className="input-group-text">Téléphonie</span>
            <div className="form-control" style={{ height: "100%" }}>
              <PhoneConfigForm errors={errors?.PhoneConfig} phoneConfig={hotel?.phoneConfig} onChange={(phoneConfig) => {
                onChange({
                  ...hotel,
                  phoneConfig
                })
              }} />
            </div>
          </div>
          <div className="input-group">
            <span className="input-group-text">Trip Advisor Config</span>
            <div className="form-control" style={{ height: "100%" }}>
              <TripAdvisorConfigForm errors={errors?.TripAdvisorConfig} tripAdvisorConfig={hotel?.tripAdvisorConfig} onChange={(tripAdvisorConfig) => {
                onChange({
                  ...hotel,
                  tripAdvisorConfig
                })
              }} />
            </div>
          </div>
          <div className="input-group">
            <span className="input-group-text">Google Config</span>
            <div className="form-control" style={{ height: "100%" }}>
              <GoogleConfigForm errors={errors?.GoogleConfig} googleConfig={hotel?.googleConfig} onChange={(googleConfig) => {
                onChange({
                  ...hotel,
                  googleConfig,
                })
              }} />
            </div>
          </div>
          <HotelTokenListForm hotelId={hotelId} hotelTokenList={hotel?.tokenList} errors={errors?.tokenList} onChange={(tokenList) => {
            onChange({
              ...hotel,
              tokenList
            })
          }} />

        </div>
      </div>}

      {focus === FocusTypeEnum.FILE && <div className="card">
        <div className="card-header text-white bg-dark">
          Gestion des Fichiers
        </div>
        <div className="card-body">
          <FileList hotelId={hotelId} />
        </div>
      </div>}

      {focus === FocusTypeEnum.DEVICE && <div className="card">
        <div className="card-header text-white bg-dark">
          Device
        </div>
        <div className="card-body">
          <DeviceList deviceList={hotel.deviceList} onChange={(deviceList) => {
            onChange({
              ...hotel,
              deviceList
            })
          }} />
        </div>
      </div>}

      {focus === FocusTypeEnum.GENERAL && <div className="card">
        <div className="card-header text-white bg-dark">
          Infos Générales
        </div>
        <div className="card-body">
          <TextInput errors={errors?.configGeneral} id={"name"} label={"Nom"} value={hotel?.configGeneral?.name || ""} onChange={(e: any) => onChange({
            ...hotel,
            configGeneral: {
              ...(hotel?.configGeneral || {}),
              name: e.target.value,
            }
          })} />
          <TextInput errors={errors?.configGeneral} id={"initials"} label={"Initiales"} value={hotel?.configGeneral?.initials || ""} onChange={(e: any) => onChange({
            ...hotel,
            configGeneral: {
              ...(hotel?.configGeneral || {}),
              initials: e.target.value,
            }
          })} />
          <TextInput errors={errors?.configGeneral} id={"email"} label={"Email"} value={hotel?.configGeneral?.email || ""} onChange={(e: any) => onChange({
            ...hotel,
            configGeneral: {
              ...(hotel?.configGeneral || {}),
              email: e.target.value,
            }
          })} />
          <TextInput errors={errors?.configGeneral} id={"address"} label={"Adresse"} value={hotel?.configGeneral?.address || ""} onChange={(e: any) => onChange({
            ...hotel,
            configGeneral: {
              ...(hotel?.configGeneral || {}),
              address: e.target.value,
            }
          })} />
          <TextInput errors={errors?.configGeneral} id={"phoneNumber"} label={"Téléphone"} value={hotel?.configGeneral?.phoneNumber || ""} onChange={(e: any) => onChange({
            ...hotel,
            configGeneral: {
              ...(hotel?.configGeneral || {}),
              phoneNumber: e.target.value,
            }
          })} />
          <NumberInput errors={errors?.configGeneral} id={"stayTax"} label={"Taxe de séjour"} value={hotel?.configGeneral?.stayTax || ""} onChange={(_, v) => onChange({
            ...hotel,
            configGeneral: {
              ...(hotel?.configGeneral || {}),
              stayTax: v,
            }
          })} />
          <ColorInput errors={errors?.themeColor} id={"themeColor"} label={"Couleur thème"} value={hotel?.configGeneral?.themeColor || ""} onChange={(_, v) => onChange({
            ...hotel,
            configGeneral: {
              ...(hotel?.configGeneral || {}),
              themeColor: v,
            }
          })} rightLabel={<span style={{ backgroundColor: `#${hotel?.configGeneral?.themeColor}` }} className="input-group-text"></span>} />

          <ColorInput errors={errors?.backgroundThemeColor} id={"backgroundThemeColor"} label={"Couleur thème Fond"} value={hotel?.configGeneral?.backgroundThemeColor || ""} onChange={(_, v) => onChange({
            ...hotel,
            configGeneral: {
              ...(hotel?.configGeneral || {}),
              backgroundThemeColor: v,
            }
          })} rightLabel={<span style={{ backgroundColor: `#${hotel?.configGeneral?.backgroundThemeColor}` }} className="input-group-text"></span>} />

          <div className="input-group">
            <span className="input-group-text">Logo</span>
            <div className="form-control" style={{ height: "100%" }}>
              {hotel?.configGeneral?.logoUrl && <img src={hotel?.configGeneral?.logoUrl} style={{ maxHeight: 100 }} className="img-responsive pb-1" alt="logoUrl" />}
              <PictureUploader onDone={(url) => {
                onChange({
                  ...hotel,
                  configGeneral: {
                    ...(hotel?.configGeneral || {}),
                    logoUrl: url,
                  }
                })
              }} />
            </div>
            {!!hotel?.configGeneral?.logoUrl && <span className="input-group-text">
              <button className="btn btn-sm btn-dark" onClick={() => {
                onChange({
                  ...hotel,
                  configGeneral: {
                    ...(hotel?.configGeneral || {}),
                    logoUrl: null,
                  }
                })
              }}>
                <IconTrash />
              </button>
            </span>}
          </div>

          <div className="input-group">
            <span className="input-group-text">Logo Monogramme</span>
            <div className="form-control" style={{ height: "100%" }}>
              {hotel?.configGeneral?.logoMonoUrl && <img src={hotel?.configGeneral?.logoMonoUrl} style={{ maxHeight: 100 }} className="img-responsive pb-1" alt="logoMonoUrl" />}
              <PictureUploader onDone={(url) => {
                onChange({
                  ...hotel,
                  configGeneral: {
                    ...(hotel?.configGeneral || {}),
                    logoMonoUrl: url,
                  }
                })
              }} />
            </div>
            {!!hotel?.configGeneral?.logoMonoUrl && <span className="input-group-text">
              <button className="btn btn-sm btn-dark" onClick={() => {
                onChange({
                  ...hotel,
                  configGeneral: {
                    ...(hotel?.configGeneral || {}),
                    logoMonoUrl: null,
                  }
                })
              }}>
                <IconTrash />
              </button>
            </span>}
          </div>
          <div className="input-group">
            <span className="input-group-text">Background</span>
            <div className="form-control" style={{ height: "100%" }}>

              {hotel?.configGeneral?.backgroundUrl && <img src={hotel?.configGeneral?.backgroundUrl} style={{ maxHeight: 100 }} className="img-responsive pb-1" alt="backgroud" />}

              <PictureUploader onDone={(url) => {
                onChange({
                  ...hotel,
                  configGeneral: {
                    ...(hotel?.configGeneral || {}),
                    backgroundUrl: url,
                  }
                })
              }} />
            </div>
            {!!hotel?.configGeneral?.backgroundUrl && <span className="input-group-text">
              <button className="btn btn-sm btn-dark" onClick={() => {
                onChange({
                  ...hotel,
                  configGeneral: {
                    ...(hotel?.configGeneral || {}),
                    backgroundUrl: null,
                  }
                })
              }}>
                <IconTrash />
              </button>
            </span>}
          </div>
          <div className="input-group">
            <span className='input-group-text'>CGV</span>
            <div className="form-control" style={{ height: "100%" }}>
              <FileInput label={"CGV URL Fr"} id={hotel?.configGeneral?.cgvUrlFrFileId || ""} onAdd={(file: File) => onChange({
                ...hotel,
                configGeneral: {
                  ...(hotel?.configGeneral || {}),
                  cgvUrlFrFileId: file.id
                }
              })} />
              <FileInput label={"CGV URL En"} id={hotel?.configGeneral?.cgvUrlEnFileId || ""} onAdd={(file: File) => onChange({
                ...hotel,
                configGeneral: {
                  ...(hotel?.configGeneral || {}),
                  cgvUrlEnFileId: file.id
                }
              })} />
            </div>
          </div>
          <div className="input-group">
            <span className='input-group-text'>Horaires</span>
            <div className="form-control" style={{ height: "100%" }}>
              <TextInput label="Check-in" id="checkInHour" value={hotel?.configGeneral?.checkInHour} errors={errors?.configGeneral} onChange={(_, v) => {
                onChange({
                  ...hotel,
                  configGeneral: {
                    ...(hotel?.configGeneral || {}),
                    checkInHour: v
                  }
                })
              }} />
              <TextInput label="Check-out" id="checkOutHour" value={hotel?.configGeneral?.checkOutHour} errors={errors?.configGeneral} onChange={(_, v) => {
                onChange({
                  ...hotel,
                  configGeneral: {
                    ...(hotel?.configGeneral || {}),
                    checkOutHour: v
                  }
                })
              }} />
              <TextInput label="PDJ Début" id="breakfastStartHour" value={hotel?.configGeneral?.breakfastStartHour} errors={errors?.configGeneral} onChange={(_, v) => {
                onChange({
                  ...hotel,
                  configGeneral: {
                    ...(hotel?.configGeneral || {}),
                    breakfastStartHour: v
                  }
                })
              }} />
              <TextInput label="PDJ Fin" id="breakfastEndHour" value={hotel?.configGeneral?.breakfastEndHour} errors={errors?.configGeneral} onChange={(_, v) => {
                onChange({
                  ...hotel,
                  configGeneral: {
                    ...(hotel?.configGeneral || {}),
                    breakfastEndHour: v
                  }
                })
              }} />

            </div>
          </div>


          <div className="input-group">
            <span className="input-group-text">Expediteur</span>
            <div className="form-control" style={{ height: "100%" }}>
              <TextInput id="name" errors={errors?.configGeneral?.emailSender?.name} label={"Nom"} value={hotel?.configGeneral?.emailSender?.name || ""} onChange={(e: any) => onChange({
                ...hotel,
                configGeneral: {
                  ...(hotel?.configGeneral || {}),
                  emailSender: {
                    ...hotel?.configGeneral?.emailSender,
                    name: e.target.value
                  },
                }
              })}
              />
              <TextInput id="address" errors={errors?.configGeneral?.emailSender?.address} label={"Adresse Mail"} value={hotel?.configGeneral?.emailSender?.address || ""} onChange={(e: any) => onChange({
                ...hotel,
                configGeneral: {
                  ...(hotel?.configGeneral || {}),
                  emailSender: {
                    ...hotel?.configGeneral?.emailSender,
                    address: e.target.value
                  },
                }
              })}
              />
            </div>
          </div>
          <div className="input-group">
            <span className='input-group-text'>Copies des envois</span>
            <div className="form-control" style={{ height: "100%" }}>
              {hotel?.configGeneral?.notificationDest?.map((dest: string, index: number) => {
                return <div className="input-group" key={`confDest_${index}`}>
                  <span className="input-group-text">Email</span>
                  <input className="form-control" type="text" value={dest} onChange={(e: any) => {
                    onChange({
                      ...hotel,
                      configGeneral: {
                        ...(hotel?.configGeneral || {}),
                        notificationDest: hotel?.configGeneral?.notificationDest?.map((el: any, id: number) => (id === index) ? e.target.value : el)
                      }
                    })
                  }} />
                  <span className="input-group-text">
                    <span className="icon-close" onClick={() => {
                      onChange({
                        ...hotel,
                        configGeneral: {
                          ...(hotel?.configGeneral || {}),
                          notificationDest: hotel?.configGeneral?.notificationDest.filter((el: any, id: number) => id !== index)
                        }
                      })
                    }} />
                  </span>

                </div>
              })}
              <div className="">
                <button className="btn btn-dark btn-sm" onClick={() => {
                  onChange({
                    ...hotel,
                    configGeneral: {
                      ...(hotel?.configGeneral || {}),
                      notificationDest: [
                        ...(hotel?.configGeneral?.notificationDest ? hotel?.configGeneral?.notificationDest : []),
                        ""
                      ],
                    }
                  })
                }}>Ajouter</button>
              </div>
            </div>
          </div>

          <BooleanInput errors={errors?.configGeneral} label={"Afficher 'Photos des chambres NC'"} id="displayRoomPictureDisclosure" value={hotel?.configGeneral?.displayRoomPictureDisclosure} onChange={(b) => onChange({
            ...hotel,
            configGeneral: {
              ...(hotel?.configGeneral || {}),
              displayRoomPictureDisclosure: b,
            }
          })} />

          <BooleanInput errors={errors?.configGeneral} label={"Afficher la Categorie des Chambres"} id="displayRoomCategoryInDetails" value={hotel?.configGeneral?.displayRoomCategoryInDetails} onChange={(b) => onChange({
            ...hotel,
            configGeneral: {
              ...(hotel?.configGeneral || {}),
              displayRoomCategoryInDetails: b,
            }
          })} />


          <div className="input-group">
            <span className="input-group-text">Notification via email</span>
            <div className="form-control" style={{ height: "100%" }}>
              {Object.keys(NotificationTypeEnum).map(key => {
                //@ts-ignore
                const type = NotificationTypeEnum[key] as NotificationTypeEnum
                return <span key={`${key}`} className="ml-2 mr-2" style={{ whiteSpace: "nowrap" }}>
                  <input type="checkbox" checked={hotel?.configGeneral?.notificationListToMail?.includes(type)} id={key} onChange={(e: any) => {
                    const checked = e.target.checked
                    onChange({
                      ...hotel,
                      configGeneral: {
                        ...(hotel?.configGeneral || {}),
                        notificationListToMail: checked ? [...(hotel?.configGeneral?.notificationListToMail ? hotel?.configGeneral?.notificationListToMail : []), type] : hotel?.configGeneral?.notificationListToMail?.filter((el: string) => el !== type)
                      }
                    })
                  }} />
                  <label className="" htmlFor={key}>{type}</label>
                </span>
              })}
            </div>
          </div>
        </div>
      </div>}

      {focus === FocusTypeEnum.SEASONALITY && <div className="card">
        <div className="card-header text-white bg-dark">
          Saisonalité
        </div>
        <div className="card-body">

          <SeasonalityList errors={errors?.configSeasonality} seasonalityList={hotel?.configSeasonality} onChange={(seasonalityList: SeasonalityInput[]) => {
            onChange({
              ...hotel,
              configSeasonality: seasonalityList,
            })
          }} hotelId={hotelId} />
        </div>
      </div>}

      {focus === FocusTypeEnum.POST_STAY && <div className="card">
        <div className="card-header text-white bg-dark">
          Post Stay
        </div>
        <div className="card-body">
          <PostStayConfigForm hotelId={hotelId} errors={errors?.configStay?.postStayConfig} postStayConfig={hotel?.configStay?.postStayConfig} onChange={(postStayConfig) => {
            onChange({
              ...hotel,
              configStay: {
                ...hotel.configStay,
                postStayConfig,
              }
            })
          }} />
        </div>
      </div>}

      {focus === FocusTypeEnum.PRE_STAY && <div className="card">
        <div className="card-header text-white bg-dark">
          Pre Stay
        </div>
        <div className="card-body">
          <PreStayConfigForm hotelId={hotelId} errors={errors?.configStay?.preStayConfig} preStayConfig={hotel?.configStay?.preStayConfig} onChange={(preStayConfig) => {
            onChange({
              ...hotel,
              configStay: {
                ...hotel.configStay,
                preStayConfig,
              }
            })
          }} />
        </div>
      </div>}

      {focus === FocusTypeEnum.PROPOSITION && <div className="card">
        <div className="card-header text-white bg-dark">
          Proposition
        </div>
        <div className="card-body">
          <PropositionConfigForm hotelId={hotelId} propositionConfig={hotel?.propositionConfig} errors={errors?.propositionConfig} onChange={config => {
            onChange({
              ...hotel,
              propositionConfig: config,
            })
          }} />
        </div>
      </div>}

      {focus === FocusTypeEnum.CONFIRMATION && <div className="card">
        <div className="card-header text-white bg-dark">
          Confirmation
        </div>
        <div className="card-body">
          <ConfirmationConfigForm hotelId={hotelId} confirmationConfig={hotel?.confirmationConfig} errors={errors?.confirmationConfig} onChange={config => {
            onChange({
              ...hotel,
              confirmationConfig: config,
            })
          }} />
        </div>
      </div>}

      {focus === FocusTypeEnum.CANCEL_CONDITION && <div className="card">
        <div className="card-header text-white bg-dark">
          Conditions d'Annulation
        </div>
        <div className="card-body">
          <PriceRateCancelConditionList hotelId={hotelId} errors={errors?.configCancelConditions?.PriceRateCancelCondition} priceRateCancelConditionList={hotel?.configCancelConditions?.PriceRateCancelCondition} onChange={(prccList) => {
            onChange({
              ...hotel,
              configCancelConditions: {
                PriceRateCancelCondition: prccList,
              }
            })
          }} />
          {/* <div className="input-group">
            <div className="form-control" style={{ height: "100%" }}>
              {hotel?.configCancelConditions?.PriceRateCancelCondition
                ?.map((cancelCondition: any, index: number) => {
                  return <div key={`room_item_${index}`} className="input-group" style={{ opacity: cancelCondition?.toBeDeleted ? 0.3 : 1 }}>
                    <div className="form-control" style={{ height: "100%" }}>
                      <PriceRateCancelConditionEdit open={false} errors={errors?.configCancelConditions?.PriceRateCancelCondition && errors?.PriceRateCancelCondition[index]} priceRateCancelCondition={cancelCondition} onChange={(cancelCondition: any) => {
                        // console.log("cancelCOnfiution", cancelCondition)
                        onChange({
                          ...hotel,
                          configCancelConditions: {
                            PriceRateCancelCondition: hotel?.configCancelConditions?.PriceRateCancelCondition.map((el: any, idx: number) => (idx === index) ? cancelCondition : el)
                          }
                        })
                      }} />
                    </div>
                    <span className="input-group-text" onClick={() => {
                      onChange({
                        ...hotel,
                        configCancelConditions: {
                          PriceRateCancelCondition: hotel?.configCancelConditions?.PriceRateCancelCondition.map((el: any, idx: number) => (idx === index) ? { ...cancelCondition, toBeDeleted: true } : el)
                        }
                      })
                    }}>
                      <span className="icon-trash" />
                    </span>
                  </div>
                })}
              <button className="btn btn-sm btn-dark" onClick={() => {
                onChange({
                  ...hotel,
                  configCancelConditions: {
                    ...hotel?.configCancelConditions,
                    PriceRateCancelCondition: [
                      ...(hotel?.configCancelConditions?.PriceRateCancelCondition || []),
                      {
                        id: uuid(),
                        label: "",
                        defaultValue: null,
                        conditionList: [],
                      },
                    ]
                  }

                })
              }}>Ajouter </button>
            </div>
          </div> */}
        </div>
      </div>}

      {focus === FocusTypeEnum.PRICE_RATE && <div className="card">
        <div className="card-header text-white bg-dark">
          Code Tarifs
        </div>
        <div className="card-body">
          <PriceRateTypeList hotelId={hotelId} errors={errors?.configPriceRate?.PriceRateType} priceRateTypeList={hotel?.configPriceRate?.PriceRateType} onChange={(priceRateTypeList) => {
            onChange({
              ...hotel,
              configPriceRate: {
                ...hotel?.configPriceRate,
                PriceRateType: priceRateTypeList,
              }
            })

          }} />

        </div>
      </div>}

      {focus === FocusTypeEnum.ROOM_TYPE && <div className="card">
        <div className="card-header text-white bg-dark">
          Type de Chambres
        </div>
        <div className="card-body">
          <RoomTypeList hotelId={hotelId} roomTypeList={hotel?.configRoomType?.RoomType} errors={errors?.configRoomType?.RoomType} onChange={(rtl) => {
            onChange({
              ...hotel,
              configRoomType: {
                ...hotel?.configRoomType,
                RoomType: rtl,
              }
            })
          }} />


          {/* <div>
            {hotel?.configRoomType?.RoomType
              // ?.sort((el1: any, el2: any) => el1?.type?.localeCompare(el2?.type))
              ?.map((roomType, index: number) => {
                return <div key={`room_item_${index}`} className="input-group">
                  <div className="form-control" style={{ height: "100%" }}>
                    <RoomTypeForm open={!roomType?.id} errors={errors?.configRoomType && errors?.configRoomType?.RoomType && errors?.configRoomType?.RoomType[index]} hotelId={props?.hotelId} roomType={roomType} onChange={(room) => {
                      onChange({
                        ...hotel,
                        configRoomType: {
                          ...hotel?.configRoomType,
                          RoomType: hotel?.configRoomType?.RoomType.map((el: any, idx: number) => (idx === index) ? room : el)
                        }
                      })
                    }} />
                  </div>
                  <span className="input-group-text" onClick={() => {
                    onChange({
                      ...hotel,
                      configRoomType: {
                        ...hotel?.configRoomType,
                        RoomType: hotel?.configRoomType?.RoomType.filter((el: any, idx: number) => (idx !== index))
                      }
                    })
                  }}>
                    <span className="icon-trash"></span>
                  </span>
                </div>
              })}
          </div>
          <div>
            <button className="btn btn-sm btn-dark" onClick={() => {
              onChange({
                ...hotel,
                configRoomType: {
                  ...hotel?.configRoomType,
                  RoomType: [
                    ...hotel?.configRoomType?.RoomType,
                    {}
                  ]
                }
              })
            }}>Ajouter</button>
          </div> */}
        </div>
      </div>}
      {focus === FocusTypeEnum.OPTIONS && <div className="card">
        <div className="card-header text-white bg-dark">
          Options
        </div>
        <div className="card-body">

          <OptionList errors={errors?.configOptions.Option} hotelId={hotelId} optionList={hotel?.configOptions?.Option} onChange={(optionList) => {
            onChange({
              ...hotel,
              configOptions: {
                ...hotel?.configOptions,
                Option: optionList,
              }
            })
          }} />

        </div>
      </div>}


      {focus === FocusTypeEnum.SERVICES && <div className="card">
        <div className="card-header text-white bg-dark">
          Services
        </div>
        <div className="card-body">

          <ServiceList serviceList={hotel?.configService?.ServiceList} errors={errors?.configService?.ServiceList} hotelId={hotelId} onChange={(serviceList) => {
            onChange({
              ...hotel,
              configService: {
                ...hotel?.configService,
                ServiceList: serviceList
              }
            })
          }} />

          {/* <div className="" style={{ height: "100%" }}>
            {hotel?.configService?.ServiceList?.map((service, index: number) => {
              return <div className="card m-1">
                <div className="card-header">Service</div>
                <div className="card-body">
                  <div className="input-group">
                    <div className="form-control" style={{ height: "100%" }}>
                      <ServiceForm errors={errors?.configService?.ServiceList && errors?.configService?.ServiceList[index]} service={service} onChange={(service) => {
                        onChange({
                          ...hotel,
                          configService: {
                            ...hotel?.configService,
                            ServiceList: hotel?.configService?.ServiceList.map((el: any, idx: number) => (idx === index) ? service : el)
                          }
                        })
                      }} />

                    </div>
                    <span className="input-group-text">
                      <button type="button" className="btn btn-dark" onClick={() => {
                        onChange({
                          ...hotel,
                          configService: {
                            ...hotel?.configService,
                            ServiceList: hotel?.configService?.ServiceList?.filter((el: any, idx: number) => idx !== index)
                          }
                        })
                      }}>
                        <span className="icon-trash" />
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            })}
            <button className="btn btn-sm btn-dark" onClick={() => {
              onChange({
                ...hotel,
                configService: {
                  ...hotel?.configService,
                  ServiceList: [
                    ...(hotel?.configService?.ServiceList || []),
                    {
                      hotelId: props?.hotelId,
                    },
                  ]
                }
              })
            }}>Ajouter</button>
          </div> */}
        </div>
      </div>}

    </div>
    }
  </div >

}

