import gql from "graphql-tag";

export default gql`

fragment BookingEngineConfigFragment on BookingEngineConfig {
  id
  roomTypeIdToIncludeInBestRate
  priceRateTypeIdToIncludeInBestRate
}

`