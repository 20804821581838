import { useQuery } from "@apollo/react-hooks"
import { BooleanInput, SelectInput, TextInput } from "@zipou/front_tools"
import templateListGql from "graphql/APP/Template/query/templateList"
import { PropositionConfigInput, Template } from "model"
import React from "react"

type PropositionConfigFormProps = {
  propositionConfig: PropositionConfigInput,
  onChange: (propositionConfig: PropositionConfigInput) => void,
  hotelId: string,
  errors: any,
}

export const PropositionConfigForm = ({ propositionConfig, hotelId, onChange, errors }: PropositionConfigFormProps) => {

  const { data } = useQuery<{ list: Template[] }>(templateListGql, {
    variables: {
      hotelId,
    }
  })
  const templateList = data?.list

  return <div>
    <BooleanInput errors={errors} label={"demoMode"} value={propositionConfig?.demoMode} onChange={(b) => onChange({
      ...propositionConfig,
      demoMode: b,
    })} />

    <BooleanInput errors={errors} label={"Afficher Conditions d'Annulation"} value={propositionConfig?.displayCancelCondition} onChange={(b) => onChange({
      ...propositionConfig,
      displayCancelCondition: b,
    })} />

    <BooleanInput errors={errors} label={"Afficher la valeur des réductions"} value={propositionConfig?.displayDiscountAmout} onChange={(b) => onChange({
      ...propositionConfig,
      displayDiscountAmout: b,
    })} />

    <SelectInput id={"templateId"} value={propositionConfig?.templateId} choiceList={templateList?.map(template => ({ id: template.id, label: template.name }))} label='Template' errors={errors} onChange={v => {
      onChange({
        ...propositionConfig,
        templateId: v,
      })
    }} />

    <TextInput id={"illustrationUrl"} value={propositionConfig?.illustrationUrl} label='IllustrationUrl' errors={errors} onChange={v => {
      onChange({
        ...propositionConfig,
        illustrationUrl: v,
      })
    }} />

    <div className="input-group">
      <span className='input-group-text'>Copies des envois</span>
      <div className="form-control" style={{ height: "100%" }}>
        {propositionConfig?.dest?.map((dest: string, index: number) => {
          return <div className="input-group" key={`confDest_${index}`}>
            <span className="input-group-text">Email</span>
            <input className="form-control" type="text" value={dest} onChange={(e: any) => {
              onChange({
                ...propositionConfig,
                dest: propositionConfig.dest?.map((el: any, id: number) => (id === index) ? e.target.value : el)
              })
            }} />
            <span className="input-group-text">
              <span className="icon-close" onClick={() => {
                onChange({
                  ...propositionConfig,
                  dest: propositionConfig?.dest?.filter((el: any, id: number) => id !== index)
                })
              }}></span>
            </span>

          </div>
        })}
        <div className="">
          <button className="btn btn-dark btn-sm" onClick={() => {
            onChange({
              ...propositionConfig,
              dest: [
                ...(propositionConfig?.dest ? propositionConfig?.dest : []),
                ""
              ],
            })
          }}>Ajouter</button>
        </div>
      </div>
    </div>

  </div>

}
