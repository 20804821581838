import gql from "graphql-tag";
import EventServiceFragment from "graphql/PLANNING/Event/fragment/EventServiceFragment";

export default gql`

  ${EventServiceFragment}

  mutation preStayEditEvent($id: String!, $eventId: String!, $publicId: String!, $input: EventPreStayInput!) {
    event: preStayEditEvent(id: $id, eventId: $eventId, publicId: $publicId, input: $input) {
      ...EventServiceFragment
    }
  }

`