
import { useQuery } from "@apollo/react-hooks"
import priceRateTypeListGql from "graphql/PMS/PriceRate/Type/query/priceRateTypeList.gql"
import roomTypeListGql from "graphql/PMS/Room/Type/query/roomTypeList.gql"
import { BookingEngineConfigInput, PriceRateType, RoomType } from "model"
import React from "react"

type BookingEngineConfigFormProps = {
  hotelId: string,
  bookingEngineConfig: BookingEngineConfigInput,
  errors: any,
  onChange: (bookingEngineConfig: BookingEngineConfigInput) => void,
}

export const BookingEngineConfigForm = ({ hotelId, bookingEngineConfig, onChange, errors }: BookingEngineConfigFormProps) => {

  //  priceRateTypeIdToIncludeInBestRate?: InputMaybe<Array<Scalars['String']>>;
  //  roomTypeIdToIncludeInBestRate?: InputMaybe<Array<Scalars['String']>>;

  const { data: { list: priceRateTypeList = [] } = {} } = useQuery<{ list: PriceRateType[] }>(priceRateTypeListGql, {
    variables: {
      hotelId,
    }
  })

  const { data: { roomTypeList = [] } = {} } = useQuery<{ roomTypeList: RoomType[] }>(roomTypeListGql, {
    variables: {
      hotelId,
    }
  })



  return <div>
    <div className="input-group">
      <span className="input-group-text">
        Type de Chambre pour Best Rate
      </span>
      <div className="form-control" style={{ height: "100%" }}>
        <div>
          {roomTypeList?.map((roomType) => {
            const isChecked = bookingEngineConfig?.roomTypeIdToIncludeInBestRate?.includes(roomType?.id)
            return <span key={`key_${roomType?.id}`} className="m-1">
              <input className="ml-1" type="checkbox" checked={isChecked} id={`${roomType?.id}`} onChange={() => {
                onChange({
                  ...bookingEngineConfig,
                  roomTypeIdToIncludeInBestRate: isChecked ? bookingEngineConfig?.roomTypeIdToIncludeInBestRate?.filter(el => el !== roomType?.id) : [...(bookingEngineConfig?.roomTypeIdToIncludeInBestRate || []), roomType?.id]
                })
              }} />
              <label htmlFor={`${roomType?.id}`}>{roomType?.type}</label>
            </span>
          })}

        </div>
      </div>
    </div>
    <div className="input-group">
      <span className="input-group-text">
        Code Tarif pour Best Rate
      </span>
      <div className="form-control" style={{ height: "100%" }}>
        <div>
          {priceRateTypeList?.map((priceRateType) => {
            const isChecked = bookingEngineConfig?.priceRateTypeIdToIncludeInBestRate?.includes(priceRateType?.id)
            return <span key={`key_${priceRateType?.id}`} className="m-1">
              <input className="ml-1" type="checkbox" checked={isChecked} id={`${priceRateType?.id}`} onChange={() => {
                onChange({
                  ...bookingEngineConfig,
                  priceRateTypeIdToIncludeInBestRate: isChecked ? bookingEngineConfig?.priceRateTypeIdToIncludeInBestRate?.filter(el => el !== priceRateType?.id) : [...(bookingEngineConfig?.priceRateTypeIdToIncludeInBestRate || []), priceRateType?.id]
                })
              }} />
              <label htmlFor={`${priceRateType?.id}`}>{priceRateType?.code}</label>
            </span>
          })}

        </div>
      </div>
    </div>
  </div >


}