import gql from "graphql-tag";
import RoomTypeFragmentGql from "../Type/fragment/RoomTypeFragment.gql";

export default gql`

  ${RoomTypeFragmentGql}

  fragment RoomFragment on Room {
    id
    number
    hotelId
    roomTypeId
    floor
    number
    nbBeds
    nbBedsMax
    RoomType {
      ...RoomTypeFragment
    }
  }
`