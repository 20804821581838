import React from 'react'
import { Event, EventStatusEnum } from "model"
import { DateTimeFormat } from 'components/common/Format/DateTimeFormat'

type EventBadgeProps = {
  event: Event,
}

export const EventBadge = (props: EventBadgeProps) => {

  const event = props?.event

  switch (event?.status) {

    case EventStatusEnum.EVENT_STATUS_CONFIRMED: {
      return <span className='badge badge-success'>
        <DateTimeFormat value={event?.utcDate} />
      </span>
    }

    case EventStatusEnum.EVENT_STATUS_PENDING: {
      return <span className='badge badge-warning'>
        <DateTimeFormat value={event?.utcDate} />
      </span>
    }

    case EventStatusEnum.EVENT_STATUS_CANCELLED: {
      return <span className='badge badge-danger'>
        <DateTimeFormat value={event?.utcDate} />
      </span>
    }

    case EventStatusEnum.EVENT_STATUS_DELETED: {
      return <span className='badge badge-danger'>
        <DateTimeFormat value={event?.utcDate} />
      </span>
    }

    default: {
      return <span className='badge'>
        <DateTimeFormat value={event?.utcDate} />
      </span>
    }


  }


}