import { useMutation } from "@apollo/react-hooks"
import { tokenContext } from "context/tokenContext"
import fileEncryptedAddGql from "graphql/APP/FileEncrypted/mutation/fileEncryptedAdd.gql"
import { getRootUrl } from "helpers/Utils"
import { CheckinIdentityPictureInput, FileEncrypted, FileEncryptedInput } from "model"
import React, { useContext, useRef, useState } from "react"


type CheckinIdentityPictureFormProps = {
  checkinIdentityPictureInput: CheckinIdentityPictureInput,
  onChange: (checkinIdentityPictureInput: CheckinIdentityPictureInput) => void,
  errors: any,
}

export const CheckinIdentityPictureForm = ({ onChange, checkinIdentityPictureInput, errors }: CheckinIdentityPictureFormProps) => {

  const [oversizeError, updateOversizeError] = useState<boolean>(false)

  const { token } = useContext(tokenContext)

  const refInputFile = useRef(null)
  const [mutate, { loading }] = useMutation<{ fileEncrypted: FileEncrypted }, { input: FileEncryptedInput }>(fileEncryptedAddGql)

  const handleButtonClick = (e: any) => {
    refInputFile.current.click(e)
  }

  const handleInputFileChange = (event: any) => {
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onload = (upload) => {
      // console.log('UPLOAD', upload)
      mutate({
        variables: {
          input: {
            base64PlainBuffer: upload.target.result as string,
          }
        }
      })
        .then((response) => {
          // response.data.picture.url
          const fileEncryptedId = response?.data?.fileEncrypted?.id
          onChange({
            encryptedFileId: fileEncryptedId
          })
        })
        .catch((e: any) => console.log("EERROR", e))
      // uploadStaticImage(upload.target.result)
      // .then((url)=> {

      // });
    }
    if (file) {
      const { size } = file;

      if (size > 1024000) {
        updateOversizeError(true)
        return;
      }

      const type = file.type.split("/")[0];
      if (type === "image") {
        reader.readAsDataURL(file);
      }
    }
  }

  return <div>
    {oversizeError && <div className="alert alert-danger">
      Attention, votre fichier et trop volumineux...
    </div>}
    {checkinIdentityPictureInput?.encryptedFileId && <div>
      <img src={`${getRootUrl()}imageEncrypted/${checkinIdentityPictureInput?.encryptedFileId}?access_token=${token}`} style={{ maxWidth: "250px" }} />
    </div>}
    {!checkinIdentityPictureInput?.encryptedFileId && <div className="d-flex align-items-center justify-content-center">
      <div style={{ width: 250, height: 150, border: "1px solid #CCC" }} className="d-flex align-items-center justify-content-center">
        <button className="btn btn-secondary" onClick={handleButtonClick} >Touchez ici</button>
      </div>
    </div>}
    <input style={{ display: "none" }} ref={refInputFile} className="form-control" type="file" accept="image/*" onChange={handleInputFileChange}  ></input>
  </div >

}
