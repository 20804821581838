import { useMutation } from "@apollo/react-hooks"
import { DateInput } from "components/common/Form/DateInput"
import preStayAddEventGql from "graphql/PMS/Stay/Pre/mutation/preStayAddEvent.gql"
// import {formatErrorResponseForJoi} from "helpers/Apollo"
import { Service, ServiceTypeEnum, EventInput, PreStay } from "model"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { EventFormPreStay } from "../Form/EventFormPreStay"

type EventAddByPreStayProps = {
  preStay: PreStay,
  service: Service,
  startDate?: Date,
  onDone?: (eventId: string) => void,
}

export const EventAddByPreStay = (props: EventAddByPreStayProps) => {

  // const [errors, updateErrors] = useState<any>()

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'prestay' });
  const isFrench = i18n.language === "fr"

  const service = props?.service
  const preStay = props?.preStay

  const [mutate] = useMutation(preStayAddEventGql)

  const [event, updateEvent] = useState<EventInput>({
    utcDate: props?.startDate,
    serviceId: service?.id,
    source: {
      nbPeople: 2,
      duration: 1,
    },
  })

  const onSave = () => {
    mutate({
      variables: {
        id: preStay?.id,
        publicId: preStay?.publicId,
        input: event
      }
    })
      .then((response) => {
        props.onDone(response?.data?.event.id)
      })
      .catch((e) => {
        // updateErrors(formatErrorResponseForJoi(e))
      })
  }

  return <div>
    <EventFormPreStay service={service} onChange={e => updateEvent(e)} event={event} />
    <div className="mt-4">
      <button className="btn btn-primary" onClick={() => onSave()}>{t("save")}</button>
    </div>
  </div>
}