import React, { useState } from "react"
import { Calendar } from "./Calendar/Calendar"
import { BookingEngineNbPeopleInput } from "model"
import { NbPeopleForm } from "./NbPeople/Form/NbPeopleForm"
import { RoomTypeSelect } from "./RoomType/Select/RoomTypeSelect"



type BookingEngineProps = {
  showBestRateInCalendar?: boolean,
  nbRoomAvailableInCalendar?: boolean,
  nbRoomFreeMinimumForDateToBeAvailable?: number,
  isFrench?: boolean,
  hotelId: string,
  style: any,
}



export const BookingEngine = ({ showBestRateInCalendar, nbRoomAvailableInCalendar, isFrench = true, nbRoomFreeMinimumForDateToBeAvailable = 3, hotelId, style }: BookingEngineProps) => {

  const now = new Date()

  const monthList = isFrench ? ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"] :
    ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

  const [availibilityMap, updateAvailibilityMap] = useState<Map<number, number>>(new Map())

  const [currentMonth, updateCurrentMonth] = useState<number>(now.getMonth() + 1)
  const [currentYear, updateCurrentYear] = useState<number>(now.getFullYear())

  const [arrivalDate, updateArrivalDate] = useState<Date | null>(null)
  const [departureDate, updateDepartureDate] = useState<Date | null>(null)

  const [nbPeople, updateNbPeople] = useState<BookingEngineNbPeopleInput>({
    nbAdults: 2,
    nbChildren: 0,
    nbSpecial: 0
  })

  const [errors, updateErrors] = useState<any>(null)

  const [isDateSelected, updateIsDateSelected] = useState<boolean>(false)

  const onMonthPrev = () => {
    if (currentMonth === 1) {
      updateCurrentMonth(12)
      updateCurrentYear(currentYear - 1)
    } else {
      updateCurrentMonth(currentMonth - 1)
    }
  }

  const onAvailibilityChange = (date: Date, nbRoom: number) => {
    console.log(`Setting availibility for ${date.toDateString()} to ${nbRoom}`)
    updateAvailibilityMap(availibilityMap.set(date.getTime(), nbRoom))
  }

  const onMonthNext = () => {
    if (currentMonth === 12) {
      updateCurrentMonth(1)
      updateCurrentYear(currentYear + 1)
    } else {
      updateCurrentMonth(currentMonth + 1)
    }
  }

  const onSelectCalendarDate = (dateSelected: Date) => {

    console.log(`Availibility for ${dateSelected.toDateString()} is ${availibilityMap.get(dateSelected.getTime())}`)

    if (!arrivalDate) {
      updateArrivalDate(dateSelected)
    } else {
      if (arrivalDate && !departureDate && dateSelected > arrivalDate) {

        //Looping through the dates between arrival and departure date
        for (let d = new Date(arrivalDate); d <= dateSelected; d.setDate(d.getDate() + 1)) {
          const availibilityForDate = availibilityMap.get(d.getTime())
          console.log(`Checking availibility for ${d.toDateString()} - ${availibilityForDate}`)
          if (availibilityForDate < nbRoomFreeMinimumForDateToBeAvailable) {
            //Trouble for availibility
            console.log(`Trouble for availibility for ${d.toDateString()} - ${availibilityForDate}`)
            updateArrivalDate(dateSelected)
            return
          }
        }

        updateDepartureDate(dateSelected)
      } else {
        updateDepartureDate(null)
        updateArrivalDate(dateSelected)
      }
    }
  }

  const onBookNow = () => {
    if (arrivalDate && departureDate) {
      updateIsDateSelected(true)
    }
  }

  const onRoomTypeSelect = (roomTypeId: string) => {

  }

  console.log("availibilityMap", availibilityMap)

  const displayBookNowButton = !!arrivalDate && !!departureDate
  const diplayRoomAvailable = !!isDateSelected && !!displayBookNowButton

  return <div className="container" style={style}>
    <h1>Booking Engine</h1>

    {/* <h2>Arrival Date: {arrivalDate?.toDateString()}</h2>
    <h2>Departure Date: {departureDate?.toDateString()}</h2> */}

    <div className="card">
      <div className="card-header d-flex flex-row justify-content-between align-items-center">

        <button className="btn btn-dark btn-sm" onClick={() => onMonthPrev()}>&lt;</button>

        <span>{monthList[currentMonth - 1]} {currentYear}</span>

        <button className="btn btn-dark btn-sm" onClick={() => onMonthNext()}>&gt;</button>
      </div>
      <div className="card-body">
        <Calendar
          hotelId={hotelId}
          nbRoomFreeMinimumForDateToBeAvailable={nbRoomFreeMinimumForDateToBeAvailable}
          arrivalDate={arrivalDate}
          departureDate={departureDate}
          month={currentMonth}
          year={currentYear}
          showBestRateInCalendar={showBestRateInCalendar || false}
          onSelectDate={(dateSelected) => onSelectCalendarDate(dateSelected)}
          onAvailabilityChange={onAvailibilityChange}
        />


      </div>
    </div>


    <NbPeopleForm nbPeople={nbPeople} errors={errors?.nbPeople} onChange={updateNbPeople} />

    {displayBookNowButton && <div className="">
      <button className="btn btn-dark btn-sm" onClick={() => onBookNow()}>
        Book Now
      </button>
    </div>}

    {diplayRoomAvailable && <div>
      <h1>Categorie de chambre</h1>
      <RoomTypeSelect startDate={arrivalDate} endDate={departureDate} nbPeople={nbPeople} hotelId={hotelId} onSelect={onRoomTypeSelect} />
    </div>}

  </div >

}