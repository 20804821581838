import { PreStayStatusEnum } from "model"
import React from "react"
import { PreStayStatus } from "./PreStayStatus"

export type PreStayStatusFilterProps = {
  statusSelectedList: PreStayStatusEnum[],
  onChange: (statusSelected: PreStayStatusEnum[]) => void,
}

export const PreStayStatusFilter = ({ statusSelectedList, onChange }: PreStayStatusFilterProps) => {



  return <div>
    <ul className="list-group">

      {Object.values(PreStayStatusEnum).map((status) => {
        const isSelected = statusSelectedList.includes(status)
        return <li className="list-group-item d-flex align-items-center justify-content-center" key={status} >
          <input id={status} checked={isSelected} type='checkbox' onClick={(e) => {
            if (isSelected) {
              onChange(statusSelectedList.filter((s) => s !== status))
            } else {
              onChange([...statusSelectedList, status])
            }
          }}>
          </input>
          <label style={{ margin: 0 }} className="ml-1" htmlFor={status}>
            <PreStayStatus status={status} />
          </label>

        </li>
      })}
    </ul>
  </div>

}