import gql from "graphql-tag";
import ServicePreStayFragment from "graphql/PMS/Service/fragment/ServicePreStayFragment";
import HotelPublicFragment from "graphql/PMS/Hotel/fragment/HotelPublicFragment";
import OptionFragmentGql from "graphql/PMS/Option/fragment/OptionFragment.gql";
import ReservationPublicFragment from "graphql/PMS/Reservation/fragment/ReservationPublicFragment";
import CheckinFragmentGql from "graphql/PMS/Checkin/fragment/CheckinFragment.gql";
import ContactPublicFragment from "graphql/PMS/Contact/fragment/ContactPublicFragment";

export default gql`

  ${OptionFragmentGql}
  ${ReservationPublicFragment}
  ${HotelPublicFragment}
  ${ServicePreStayFragment}
  ${OptionFragmentGql}
  ${CheckinFragmentGql}
  ${ContactPublicFragment}

  fragment PreStayFragment on PreStay {
    id
    publicId
    hotelId
    responseStatus
    preStayContent {
      arrival {
        arrivalMinutes
        arrivalHours
        charging
        parking
        transfert
        comment
      }
      optionsSelected {
        quantity
        optionId
        Option {
          ...OptionFragment
        }
      }
      comment
    }
    Contact {
      ...ContactPublicFragment
    }
    Checkin {
      ...CheckinFragment
    }
    optionList {
      ...OptionFragment
    }
    serviceList {
      ...ServicePreStayFragment
    }
    Reservation {
      ...ReservationPublicFragment
    }
    Hotel {
      ...HotelPublicFragment
    }
  }

`