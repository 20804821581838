import { PriceInput } from "components/common/Form/PriceInput"
import { TextInput } from "components/common/Form/TextInput"
import { Confirmation, ConfirmationDataInput, ConfirmationDataParkingEnum, ConfirmationDataTypeEnum } from "model"
import React from "react"
import { ConfirmationAnswerService } from "../../Answer/Service/ConfirmationAnswerService"
import { SelectInput } from "components/common/Form/SelectInput"

type ConfirmationDataFormProps = {
  confirmationDataTypeActive: (ConfirmationDataTypeEnum | string)[],
  confirmationData: ConfirmationDataInput,
  confirmation: Confirmation,
  onChange: (input: ConfirmationDataInput) => void,
  errors?: any,
}



export const ConfirmationDataForm = ({ confirmationData, confirmation, confirmationDataTypeActive, errors, onChange }: ConfirmationDataFormProps) => {

  const hasParking = confirmationDataTypeActive?.includes(ConfirmationDataTypeEnum.TYPE_PARKING)
  const hasArrhes = confirmationDataTypeActive?.includes(ConfirmationDataTypeEnum.TYPE_ARRHES)
  const hasComment = confirmationDataTypeActive?.includes(ConfirmationDataTypeEnum.TYPE_COMMENT)
  const hasService = confirmationDataTypeActive?.includes(ConfirmationDataTypeEnum.TYPE_SERVICES)

  const contactList = confirmation?.ContactList || []
  const confirmationId = confirmation?.id
  const hotelId = confirmation?.hotelId

  const hasMultipleContact = contactList?.length > 1
  const hasMultiplePriceRate = confirmation?.PriceRateType?.length > 1

  const forcedContactId = confirmationData?.forcedContactId || confirmation?.Contact?.id || ""

  return <>


    {hasMultiplePriceRate && <div className="input-group">
      <span className="input-group-text">Forcer un Code Tarif</span>
      <select className="form-control" value={confirmationData?.forcedPriceRateTypeId || ""} onChange={e => {
        onChange({
          ...confirmationData,
          forcedPriceRateTypeId: e.target.value,
        })
      }}>
        <option value={""}>Non</option>
        {confirmation?.PriceRateType?.map((ppType) => {
          return <option key={`price_rate_type_${ppType?.id}`} value={ppType?.id}>{ppType.code}</option>
        })}
      </select>
    </div>}

    {hasMultipleContact && <div className="input-group">
      <span className="input-group-text">Choisissez un Destinataire</span>
      <select className="form-control" value={forcedContactId || ""} onChange={e => {
        onChange({
          ...confirmationData,
          forcedContactId: e.target.value,
        })
      }}>
        <option value={""}>Choisissez un contact</option>
        {contactList?.map(c => {
          return <option value={c.id} key={`contact_id_${c?.id}`}>
            {c.firstName || ""} {c.lastName || ""} {c.companyName || ""}
          </option>
        })}
      </select>
    </div>}

    {hasParking && <SelectInput choiceList={[
      { id: ConfirmationDataParkingEnum.HAS_PARKING, label: "Parking Inclus" },
      { id: ConfirmationDataParkingEnum.NO_PARKING, label: "Pas de Parking" },
      { id: ConfirmationDataParkingEnum.FREE_PARKING, label: "Parking Offert" },
      { id: ConfirmationDataParkingEnum.PARKING_FULL, label: "Parking Complet" },
    ]} label="Parking ?" errors={errors} value={confirmationData?.parking} onChange={(_, v) => {
      onChange({
        ...confirmationData,
        parking: v as ConfirmationDataParkingEnum,
      })
    }}
    />}
    {hasArrhes && <PriceInput label="Montant des Arrhes" isError={errors?.arrhesAmountInCents} value={confirmationData?.arrhesAmountInCents} onChange={(v) => {
      onChange({
        ...confirmationData,
        arrhesAmountInCents: v,
      })
    }} />}
    {hasComment && <TextInput maxLength={350} errors={errors} label="Commentaire" value={confirmationData?.comment || ""} onChange={(_, value) => {
      onChange({
        ...confirmationData,
        comment: value,
      })
    }} />}
    {hasService && <div className="input-group">
      <span className="input-group-text">Services associés</span>
      <div className="form-control" style={{ height: "100%" }}>
        <ConfirmationAnswerService confirmationId={confirmationId} hotelId={hotelId} />
      </div>
    </div>}

  </>

}