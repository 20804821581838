import React, { useState } from "react"
import { Modal } from "@zipou/front_tools"

type ConfirmButtonProps = {
  onClick: () => void,
  children: (onClick: () => void) => any,
  message: string,
}

export const ConfirmButton = ({ onClick, children, message }: ConfirmButtonProps) => {

  const [displayModal, updateDisplayModal] = useState(false)

  const handleAction = () => {
    onClick && onClick()
    updateDisplayModal(false)
  }

  return <>
    {children(() => updateDisplayModal(true))}
    {displayModal && <Modal display title={message || ""} onClose={() => updateDisplayModal(false)}>
      <div>
        <button className="btn btn-sm btn-warning ml-1" onClick={() => handleAction()}>Confirmer</button>
        <button className="btn btn-sm btn-secondary ml-1 " onClick={() => updateDisplayModal(false)}>Annuler</button>
      </div>
    </Modal >}
  </>

}