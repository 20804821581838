import React from "react"
import { BookingEngineRoomTypeDisplay } from "../../Display/RoomTypeDisplay"
import { BookingEngineRoomTypeAvailableItem } from "model"
import { useQuery } from "@apollo/react-hooks"
import bookingEngineBestRateForRoomTypeAndStayGql from "graphql/PMS/BookingEngine/query/bookingEngineBestRateForRoomTypeAndStay.gql"

type RoomTypeSelectItemProps = {
  hotelId: string,
  roomTypeAvailable: BookingEngineRoomTypeAvailableItem,
  startDate: Date,
  endDate: Date,
  onSelect: (roomTypeId: string) => void
}

export const RoomTypeSelectItem = ({ roomTypeAvailable, hotelId, startDate, endDate, onSelect }: RoomTypeSelectItemProps) => {

  const roomTypeId = roomTypeAvailable.categoryIdList[0]

  const { data } = useQuery<{ bestRate: number }>(bookingEngineBestRateForRoomTypeAndStayGql, {
    variables: {
      hotelId,
      startDate,
      endDate,
      roomTypeId,
    }
  })

  const bestRate = data?.bestRate

  return <div>
    <BookingEngineRoomTypeDisplay roomTypeId={roomTypeId} />

    <div className="pt-2 d-flex flex-row justify-content-center align-items-center">

      <span>
        A partir de {bestRate || -1} Euros
      </span>

      <button className="btn btn-dark btn-sm ml-4 " onClick={() => {
        onSelect(roomTypeId)
      }}>
        Réserver
      </button>

    </div>

    <div>
    </div>

  </div>
}