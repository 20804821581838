import { useSubscription } from "@apollo/react-hooks"
import { IconLoading, IconSave, Modal } from "@zipou/front_tools"
import { tokenContext } from "context/tokenContext"
import segmentationDownloadProgressGql from "graphql/CRM/Segmentation/subscription/segmentationDownloadProgress.gql"
import { getRootUrl } from "helpers/Utils"
import React, { useContext } from "react"
import { useState } from "react"

type SegmentationExportProps = {
  segmentationId: string,
}


export const SegmentationExport = ({ segmentationId }: SegmentationExportProps) => {


  // const [blob, updateBlob] = useState<any>()
  const [url, updateUrl] = useState<any>()
  const [loading, updateLoading] = useState(false)
  const [displayModal, updateDisplayModal] = useState(false)
  const [progress, updateProgress] = useState(0)

  const { token } = useContext(tokenContext)


  useSubscription(segmentationDownloadProgressGql, {
    variables: {
      segmentationId
    },
    // skip: !!segmentationId,
    onSubscriptionData: (data) => {
      updateProgress(data?.subscriptionData?.data?.progress?.progress)
    }
  })


  const onModalDisplay = () => {
    updateDisplayModal(true)
    if (!url && !loading) {
      downloadFile()
    }

  }

  const downloadFile = () => {
    updateLoading(true)
    updateDisplayModal(true)
    const url = `${getRootUrl()}crm/contact_list/export/csv/${segmentationId}?access_token=${token}`
    fetch(url, {
      headers: {
        'Content-Type': 'text/csv',
      },
    }).then(response => {
      return response.blob()
    })
      .then(blob => {
        // updateBlob(blob)
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        updateUrl(url)
        updateLoading(false)
      })
  }

  return <>
    <Modal display={displayModal} title="Telecharger" onClose={() => updateDisplayModal(false)}>
      {!url && <div>
        <div>
          <IconLoading />
          Préparation du fichier...
        </div>
        <div className="progress">
          <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ "width": `${progress}%` }}></div>
        </div>
      </div>
      }
      {!!url && <a href={url} download={"Export.csv"}>
        <button className="btn btn-dark">Telecharger Ici</button>
      </a>}
    </Modal>
    <button className={!!url ? "ml-1 btn btn-sm btn-success" : "ml-1 btn btn-sm btn-dark"} onClick={() => onModalDisplay()}>
      {loading && <IconLoading />}
      <IconSave style={{ fontSize: 12 }} />
    </button>
  </>


}