import gql from "graphql-tag";
import ContactFragment from "graphql/PMS/Contact/fragment/ContactFragment";
import HotelConfirmationFragment from "graphql/PMS/Hotel/fragment/HotelConfirmationFragment";
import ReservationConfirmationFragmentGql from "graphql/PMS/Reservation/fragment/ReservationConfirmationFragment.gql";
import ReservationGroupConfirmationFragmentGql from "graphql/PMS/Reservation/Group/fragment/ReservationGroupConfirmationFragment.gql";
import ConfirmationDataFragment from "../Data/fragment/ConfirmationDataFragment";
import ConfirmationConfigFragmentGql from "../Config/fragment/ConfirmationConfigFragment.gql";
import PriceRateTypeFragmentGql from "graphql/PMS/PriceRate/Type/fragment/PriceRateTypeFragment.gql";

export default gql`

${HotelConfirmationFragment}
${ContactFragment}
${ConfirmationDataFragment}

${ReservationConfirmationFragmentGql}
${ReservationGroupConfirmationFragmentGql}
${ConfirmationConfigFragmentGql}
${PriceRateTypeFragmentGql}

fragment ConfirmationFragment on Confirmation{
  id
  creationDate
  status
  type
  hotelId
  confirmationData {
    ...ConfirmationDataFragment
  }
  ContactList {
    ...ContactFragment
  }
  ConfirmationConfig {
    ...ConfirmationConfigFragment
  }
  Hotel {
    ...HotelConfirmationFragment
  }
  Reservation {
    ...ReservationConfirmationFragment
  }
  Contact {
    ...ContactFragment
  }
  PriceRateType {
    ...PriceRateTypeFragment
  }
  ReservationGroup {
    ...ReservationGroupConfirmationFragment
  }

}

`