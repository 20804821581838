import { useQuery } from "@apollo/react-hooks"
import { tokenContext } from "context/tokenContext"
import { userContext } from "context/User"
import confirmationGet from "graphql/PMS/Confirmation/query/confirmationGet"
import { getRootUrl } from "helpers/Utils"
import { Confirmation, UserRoleEnum } from "model"
import React, { useContext } from "react"

export type ConfirmationPreviewProps = {
  confirmationId: string,
  onSend: () => void,
}


export const ConfirmationPreview = (props: ConfirmationPreviewProps) => {

  const { data } = useQuery(confirmationGet, {
    variables: {
      id: props?.confirmationId,
    },
    skip: !props?.confirmationId,
  })


  const confirmation = data?.confirmation

  const isConfirmationSendable = (confirmation: Confirmation) => {
    return confirmation?.status === "CONFIRMATION_STATUS_TO_VALID"
    // return confirmation?.status === "CONFIRMATION_STATUS_TO_VALID" &&  (!confirmation.reservationGroupId && confirmation.Reservation?.ReservationRoom?.Room?.RoomType?.id)
  }

  const isConfirmationReSendable = (confirmation: any) => {
    return confirmation?.status === "CONFIRMATION_STATUS_SENT" || confirmation?.status === "CONFIRMATION_STATUS_DELIVERED" || confirmation?.status === "CONFIRMATION_STATUS_READ"
  }

  const user = useContext(userContext)
  const hasDebugRole = user.roles?.includes(UserRoleEnum.ROLE_DEBUG)

  const { token } = useContext(tokenContext)



  return <div className="card">
    {hasDebugRole && <div className="card-header">Identifiant: {confirmation?.id}</div>}
    {confirmation && <div>

      <div className="card-body">
        <iframe style={{ height: '80vh' }} width='100%' title='confirmation' src={`${getRootUrl()}confirmation/${confirmation?.id}?access_token=${token}`}></iframe>
      </div>
      <div className="card-footer">
        {isConfirmationSendable(confirmation) && <button className="btn btn-success" onClick={() => props?.onSend()}>Envoyer</button>}
        {isConfirmationReSendable(confirmation) && <button className="btn btn-warning" onClick={() => props?.onSend()}>Renvoyer à nouveau</button>}
      </div>
    </div>}
  </div>

}