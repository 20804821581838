import React, { useContext, useState } from "react"

import { useMutation, useQuery } from "@apollo/react-hooks"
import propositionListGql from "graphql/PMS/Proposition/query/propositionList.gql"
import { DateFormat } from "components/common/Format/DateFormat"
import { PropositionStatus } from "../Status/PropositionStatus"
import { URL_PROPOSITION_ADD, URL_PROPOSITION_DISPLAY, URL_PROPOSITION_EDIT } from "constant/url"
import { Link, useHistory } from "react-router-dom"
import propositionDeleteGql from "graphql/PMS/Proposition/mutation/propositionDelete.gql"
import { TemporisationButton } from "components/common/Navigation/Button/TemporisationButton"
import { ExpirationStatus } from "../Expiration/PropositionExpiration"
import { Pagination } from "components/common/Navigation/Pagination/Pagination"
import { PropositionStatusFilter } from "../Status/PropositionStatusFilter"
import { userContext } from "context/User"
import { HotelBadge } from "../../Hotel/Badge/HotelBadge"
import { Proposition, PropositionPushPmsEnum, PropositionPushStatusEnum, PropositionSentStatusEnum, PropositionStatusEnum } from "model"
import { ContactEditButton } from "../../Contact/Widget/ContactEditButton"
import { TextInput } from "components/common/Form/TextInput"
import { IconEdit, IconSearch, IconTrash, Modal } from "@zipou/front_tools"
import { PropositionPushStatusDisplay } from "../Push/Status/Display/PropositionPushStatusDisplay"
import { PropositionSentStatus } from "../Status/PropositionSentStatus"

export const PropositionList = () => {

  const history = useHistory()
  const user = useContext(userContext)

  const [statusFilters, updateStatusFilters] = useState<any>([
    PropositionStatusEnum.STATUS_CONFIRMED,
    PropositionStatusEnum.STATUS_DRAFT,
    PropositionStatusEnum.STATUS_VALID,
    PropositionStatusEnum.STATUS_SENT,
    PropositionStatusEnum.STATUS_DELIVERED,
    PropositionStatusEnum.STATUS_DELIVERED,
    PropositionStatusEnum.STATUS_READ,
    PropositionStatusEnum.STATUS_WAITING_PAYMENT,
    PropositionStatusEnum.STATUS_SELECTED,
    PropositionStatusEnum.STATUS_ERROR,
  ])
  const [hotelIdsFilter, updateHotelIdsFilter] = useState<any>(user?.hotels)
  const [focusStatus, updateFocusStatus] = useState<any>(false)
  const [focusContactString, updateFocusContactString] = useState<any>(false)
  const [contactString, updateContactString] = useState<string>(null)

  const [focusHotelIds, updateFocusHotelIds] = useState<any>(false)

  const { data, refetch } = useQuery(propositionListGql, {
    fetchPolicy: "cache-and-network",
    variables: {
      cursor: {
        page: 1,
        limit: 20,
      },
      input: {
        orderField: "proposition.creationDate",
        orderDirection: "DESC",
        status: statusFilters,
        hotelIdsList: hotelIdsFilter,
        contactString,
      },
    },
    skip: !user,
  })

  const [deleteMutate] = useMutation(propositionDeleteGql, {
    refetchQueries: [{
      query: propositionListGql,
      variables: {
        cursor: {
          page: data?.list?.cursor?.page,
          limit: data?.list?.cursor?.limit,
        },
        input: data?.list.input,
      }
    }]
  })

  const propositionList = data?.list?.nodes

  const onDelete = (id: string) => {
    deleteMutate({
      variables: {
        id,
      },
    })
  }

  const isMulti = user?.hotels?.length > 1

  const hasResults = propositionList?.length > 0

  return <div>

    {focusStatus && <Modal display={true} onClose={() => updateFocusStatus(false)}>
      <PropositionStatusFilter value={statusFilters} onChange={(value: any) => {
        updateStatusFilters(value)
      }} />
    </Modal>}
    <Modal display={focusContactString} onClose={() => updateFocusContactString(false)}>
      <TextInput value={contactString || ""} label="Chercher par nom de famille" onChange={(_, v) => {
        updateContactString(v)
      }} rightLabel={<span onClick={() => updateContactString(null)}>
        <span className="icon-trash"></span>
      </span>} />
    </Modal>
    <Modal display={!!focusHotelIds} onClose={() => updateFocusHotelIds(null)}>
      <button className="btn btn-sm btn-warning" onClick={() => updateHotelIdsFilter([])}>Clear All</button>
      <button className="btn btn-sm btn-primary" onClick={() => updateHotelIdsFilter(user?.Hotel?.map((h: any) => h.id))} style={{ marginLeft: 5 }}>Select All</button>
      <ul className="list-group">
        {user?.Hotel?.map((hotel: any) => {
          const isChecked = hotelIdsFilter?.includes(hotel?.id);
          return <li key={`hotel_filter_${hotel?.id}`} className="list-group-item" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <input type="checkbox" checked={isChecked} id={`cb_${hotel?.id}`} onChange={(e: any) => updateHotelIdsFilter(e.target.checked ? [...(hotelIdsFilter || []), hotel?.id] : hotelIdsFilter?.filter((el: any) => el !== hotel?.id))}></input>
            <label htmlFor={`cb_${hotel?.id}`}><HotelBadge hotel={hotel} /></label>
          </li>
        })}
      </ul>
    </Modal>

    <table className="table table-stripped table-bordered">
      <thead>
        <tr className="bg-dark text-white">
          {isMulti && <th className="text-white align-middle">
            <button className="btn btn-secondary btn-sm" style={{ whiteSpace: "nowrap" }} onClick={() => {
              updateFocusHotelIds(true)
            }}>
              Hotel
            </button>
          </th>}
          <th className="text-white align-middle">
            <button type="button" className="btn btn-sm btn-secondary" style={{ whiteSpace: "nowrap" }} onClick={() => {
              refetch({
                ...data?.list?.cursor,
                input: {
                  ...data?.list.input,
                  orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                  orderField: "proposition.publicId",
                },
              })
            }}>
              Identifiant {data?.list.input?.orderField === "proposition.publicId" && <span className={(data?.list.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
            </button>
          </th>
          <th className="text-white align-middle">
            <button type="button" className="btn btn-sm btn-secondary" style={{ whiteSpace: "nowrap" }} onClick={() => { updateFocusContactString(true) }}>
              {contactString && <span className="icon-flag mr-2" style={{ fontSize: 10 }} />}
              Contact
            </button>
          </th>
          <th className="text-white align-middle">
            <button type="button" className="btn btn-sm btn-secondary" style={{ whiteSpace: "nowrap" }} onClick={() => {
              refetch({
                ...data?.list?.cursor,
                input: {
                  ...data?.list.input,
                  orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                  orderField: "proposition.creationDate",
                },
              })
            }}>
              Date Création {data?.list.input?.orderField === "proposition.creationDate" && <span className={(data?.list.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
            </button>
          </th>
          <th className="text-white align-middle">
            <button type="button" className="btn btn-sm btn-secondary" onClick={() => updateFocusStatus(true)}>
              Status <span className="badge badge-sm badge-dark">{statusFilters?.length}</span>
            </button>
          </th>
          <th className="text-white align-middle">
            <button type="button" className="btn btn-sm btn-secondary" style={{ whiteSpace: "nowrap" }} onClick={() => {
              refetch({
                ...data?.list?.cursor,
                input: {
                  ...data?.list.input,
                  orderDirection: (data?.list.input?.orderDirection === "ASC") ? "DESC" : "ASC",
                  orderField: "proposition.expirationDate",
                },
              })
            }}>
              Expiration {data?.list.input?.orderField === "proposition.expirationDate" && <span className={(data?.list.input?.orderDirection === "ASC") ? "icon-arrow-up" : "icon-arrow-down"} />}
            </button>
          </th>
          <th className="text-white align-middle">Actions</th>
        </tr>
      </thead>
      <tbody>
        {!hasResults && <tr>
          <td colSpan={isMulti ? 9 : 8}>
            <div className="alert alert-warning">Pas de résultats</div>
          </td>
        </tr>}
        {hasResults && propositionList?.map((prop: Proposition) => {

          const isViewable = prop?.status !== PropositionStatusEnum.STATUS_DELETED
          const isPushable = (prop?.pushPms && prop?.pushPms !== PropositionPushPmsEnum.PROPOSITION_PUSH_NONE && prop.status === PropositionStatusEnum.STATUS_CONFIRMED)

          return <tr key={`prop_${prop?.id}`}>
            {isMulti && <td><HotelBadge hotel={prop?.Hotel} /></td>}
            <td>{prop?.publicId}</td>
            <td style={{ whiteSpace: "nowrap" }}>
              {prop?.Contact && <ContactEditButton contact={prop?.Contact} />}
            </td>
            <td><DateFormat value={new Date(prop?.creationDate)} /></td>
            {/* <td><DateFormat value={new Date(prop?.arrivalDate)} /></td>
            <td><DateFormat value={new Date(prop?.departureDate)} /></td> */}
            {/* <td>{prop?.Hotel?.name}</td> */}
            <td>

              <span className="ml-1">
                <PropositionStatus value={prop?.status as PropositionStatusEnum} />

              </span>
              <br></br>
              <span className="ml-1">
                <PropositionSentStatus value={prop?.sentStatus as PropositionSentStatusEnum} />
              </span>
              {isPushable && <><br></br>
                <span className="ml-1">
                  <PropositionPushStatusDisplay status={prop?.pushStatus as PropositionPushStatusEnum} />
                </span>
              </>}
            </td>
            <td>{prop?.expirationDate && <ExpirationStatus date={new Date(prop?.expirationDate)} />}</td>
            <td>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {isViewable && <Link to={URL_PROPOSITION_DISPLAY?.replace(":id", prop?.id)}>
                  <button className="btn btn-dark btn-sm  ml-2">
                    <IconSearch />
                  </button>
                </Link>}
                <button className="btn btn-sm btn-warning ml-2" onClick={() => history.push(URL_PROPOSITION_EDIT.replace(":id", prop?.id))}>
                  <IconEdit />
                </button>
                <TemporisationButton seconds={4} onClick={() => onDelete(prop?.id)}>
                  {(onClick, spinnerComponent) => <button className="btn btn-sm btn-danger  ml-2" onClick={onClick}>
                    {spinnerComponent}
                    <IconTrash />
                  </button>}
                </TemporisationButton>
              </div>
            </td>
          </tr>
        })}
      </tbody>
    </table>
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Pagination cursor={data?.list?.cursor} onChange={page => {
        refetch({
          cursor: {
            page,
            limit: data?.list?.cursor?.limit,
          },
          input: data?.list.input,
        })
      }
      } />
    </div>
    <div className="">
      <button className="btn btn-dark btn-sm" onClick={() => history.push(URL_PROPOSITION_ADD)}>Créer</button>
    </div>
  </div>

}