import { useMutation } from "@apollo/react-hooks"
import { IconCheck, IconLoading, IconWarning } from "@zipou/front_tools"
import contactUpdateGql from "graphql/PMS/Contact/mutation/contactUpdate.gql"
import { Checkin, ContactUpdateInput, Contact } from "model"
import React, { useState } from "react"

type ContactMatchCheckinProps = {
  checkin: Checkin,
  contact: Contact,
  onContactUpdate?: () => void,
}

export const ContactMatchCheckin = ({ checkin, contact, onContactUpdate }: ContactMatchCheckinProps) => {

  const [errors, updateErrors] = useState(false)
  const [mutate, { loading }] = useMutation<{ contaxt: Contact }, { id: string, input: ContactUpdateInput }>(contactUpdateGql)

  const [pendingUpdate, updatePendingUpdate] = useState<{ address: boolean, city: boolean, zip: boolean, lastname: boolean, phone: boolean, email: boolean, country: boolean }>({
    address: false,
    country: false,
    city: false,
    zip: false,
    lastname: false,
    phone: false,
    email: false,
  })


  const onUpdate = () => {
    mutate({
      variables: {
        id: contact?.id,
        input: {
          ...(pendingUpdate?.lastname ? { lastname: checkin?.name } : {}),
          ...(pendingUpdate?.phone ? { lastname: checkin?.PhoneFiltered } : {}),
          ...(pendingUpdate?.email ? { email: checkin?.mail } : {}),
          ...(pendingUpdate?.address ? { address1: checkin?.address } : {}),
          ...(pendingUpdate?.zip ? { zipcode: checkin?.zip } : {}),
          ...(pendingUpdate?.city ? { city: checkin?.city } : {}),
          ...(pendingUpdate?.country ? { country: checkin?.country } : {}),
        }
      }
    })
      .then(() => {
        console.log("OK")
        onContactUpdate && onContactUpdate()
      })
      .catch(() => {
        updateErrors(true)
      })
  }



  const isNameOk = checkin?.name === contact?.lastName
  const isPhoneOk = checkin?.PhoneFiltered === contact?.phoneNumberFiltered
  const isEmailOk = checkin?.mail === contact?.email
  const isAddressOk = checkin?.address === contact?.address1
  const isZipOk = checkin?.zip === contact?.zipcode
  const isCityOk = checkin?.city === contact?.city
  const isCountryOk = checkin?.country === contact?.country

  const hasPending = !!Object.values(pendingUpdate).reduce((acc, el) => acc ? acc : el)

  return <div>
    {!!errors && <div className="alert alert-danger">
      Erreur :(
    </div>}
    <table className="table table-bordered table-striped">
      <thead>
        <tr className="bg-secondary text-white">
          <th className="bg-secondary text-white"></th>
          <th className="bg-secondary text-white">Pre Checkin</th>
          <th className="bg-secondary text-white">Cardex</th>
          <th className="bg-secondary text-white"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Nom</th>
          <td>{checkin?.name}</td>
          <td>{contact?.lastName}</td>
          <td>
            {isNameOk && <span style={{ color: "green", fontSize: "1.5em" }}>
              <IconCheck />
            </span>}
            {!isNameOk && <div className="">
              <span style={{ color: "", fontSize: "1.5em" }}>
                <IconWarning />
              </span>
              {!pendingUpdate?.lastname && <button className=" ml-2 btn btn-sm btn-warning" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                lastname: true
              })}>Modifier</button>}
              {!!pendingUpdate?.lastname && <button className=" ml-2 btn btn-sm btn-danger" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                lastname: false
              })}>Annuler</button>}
            </div>}
          </td>
        </tr>
        <tr>
          <th>Téléphone</th>
          <td>{checkin?.phone}</td>
          <td>{contact?.phone1}</td>
          <td>
            {isPhoneOk && <span style={{ color: "green", fontSize: "1.5em" }}>
              <IconCheck />
            </span>}
            {!isPhoneOk && <div className="">
              <span style={{ color: "", fontSize: "1.5em" }}>
                <IconWarning />
              </span>
              {!pendingUpdate?.phone && <button className=" ml-2 btn btn-sm btn-warning" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                phone: true
              })}>Modifier</button>}
              {!!pendingUpdate?.phone && <button className=" ml-2 btn btn-sm btn-danger" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                phone: false
              })}>Annuler</button>}
            </div>}
          </td>
        </tr>
        <tr>
          <th>Email</th>
          <td>{checkin?.mail}</td>
          <td>{contact?.email}</td>
          <td>
            {isEmailOk && <span style={{ color: "green", fontSize: "1.5em" }}>
              <IconCheck />
            </span>}
            {!isEmailOk && <div className="">
              <span style={{ color: "", fontSize: "1.5em" }}>
                <IconWarning />
              </span>
              {!pendingUpdate?.email && <button className=" ml-2 btn btn-sm btn-warning" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                email: true
              })}>Modifier</button>}
              {!!pendingUpdate?.email && <button className=" ml-2 btn btn-sm btn-danger" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                email: false
              })}>Annuler</button>}
            </div>}

          </td>
        </tr>
        <tr className={!!pendingUpdate?.address ? "bg-warning" : ""}>
          <th>Adresse</th>
          <td>{checkin?.address}</td>
          <td>{contact?.address1}</td>
          <td>
            {isAddressOk && <span style={{ color: "green", fontSize: "1.5em" }}>
              <IconCheck />
            </span>}
            {!isAddressOk && <div className="">
              <span style={{ color: "", fontSize: "1.5em" }}>
                <IconWarning />
              </span>
              {!pendingUpdate?.address && <button className=" ml-2 btn btn-sm btn-warning" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                address: true
              })}>Modifier</button>}
              {!!pendingUpdate?.address && <button className=" ml-2 btn btn-sm btn-danger" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                address: false
              })}>Annuler</button>}
            </div>}
          </td>
        </tr>
        <tr>
          <th>CP</th>
          <td>{checkin?.zip}</td>
          <td>{contact?.zipcode}</td>
          <td>
            {isZipOk && <span style={{ color: "green", fontSize: "1.5em" }}>
              <IconCheck />
            </span>}
            {!isZipOk && <div className="">
              <span style={{ color: "", fontSize: "1.5em" }}>
                <IconWarning />
              </span>
              {!pendingUpdate?.zip && <button className=" ml-2 btn btn-sm btn-warning" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                zip: true
              })}>Modifier</button>}
              {!!pendingUpdate?.zip && <button className=" ml-2 btn btn-sm btn-danger" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                zip: false
              })}>Annuler</button>}
            </div>}
          </td>
        </tr>
        <tr>
          <th>Ville</th>
          <td>{checkin?.city}</td>
          <td>{contact?.city}</td>
          <td>
            {isCityOk && <span style={{ color: "green", fontSize: "1.5em" }}>
              <IconCheck />
            </span>}
            {!isCityOk && <div className="">
              <span style={{ color: "", fontSize: "1.5em" }}>
                <IconWarning />
              </span>
              {!pendingUpdate?.zip && <button className=" ml-2 btn btn-sm btn-warning" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                zip: true
              })}>Modifier</button>}
              {!!pendingUpdate?.zip && <button className=" ml-2 btn btn-sm btn-danger" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                zip: false
              })}>Annuler</button>}
            </div>}
          </td>
        </tr>
        <tr>
          <th>Pays</th>
          <td>{checkin?.country}</td>
          <td>{contact?.country}</td>
          <td>
            {isCountryOk && <span style={{ color: "green", fontSize: "1.5em" }}>
              <IconCheck />
            </span>}
            {!isCountryOk && <div className="">
              <span style={{ color: "", fontSize: "1.5em" }}>
                <IconWarning />
              </span>
              {!pendingUpdate?.country && <button className=" ml-2 btn btn-sm btn-warning" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                country: true
              })}>Modifier</button>}
              {!!pendingUpdate?.country && <button className=" ml-2 btn btn-sm btn-danger" onClick={() => updatePendingUpdate({
                ...pendingUpdate,
                country: false
              })}>Annuler</button>}
            </div>}
          </td>
        </tr>
      </tbody>
    </table>

    {
      hasPending && <div>
        <button disabled={loading} className="btn btn-sm btn-warning" onClick={() => onUpdate()}>
          {loading && <span className="mr-1">
            <IconLoading />
          </span>}
          Enregistrer les modifications
        </button>
      </div>
    }

  </div >

}