import { NumberInput, SelectInput, TextInput } from "@zipou/front_tools"
import { hotelContext } from "context/hotelContext"
import { RoomInput } from "model"
import React, { useContext } from "react"

type RoomFormProps = {
  room: RoomInput,
  onChange: (room: RoomInput) => void,
  errors: any,
}


export const RoomForm = ({ room, onChange, errors }: RoomFormProps) => {

  const hotel = useContext(hotelContext)

  const roomTypeList = hotel?.RoomType


  return <div>

    <TextInput label="Numéro" value={room?.number || ""} errors={errors} onChange={(value) => {
      onChange({
        ...room,
        number: value
      })
    }}
    />

    <SelectInput value={room?.roomTypeId} label="Type de Chambre" choiceList={roomTypeList?.map(el => ({ id: el.id, label: `${el.labelFr} - ${el.type}` }))} errors={errors} onChange={(value) => {
      onChange({
        ...room,
        roomTypeId: value
      })
    }}
    />

    <TextInput label="Etage" value={room?.floor || ""} errors={errors} onChange={(value) => {
      onChange({
        ...room,
        floor: value
      })
    }}
    />

    <NumberInput id="nbBeds" label="Nb de Lits" value={`${room?.nbBeds}` || ""} errors={errors} onChange={(_, v) => {
      onChange({
        ...room,
        nbBeds: v
      })
    }}
    />

    <NumberInput id="nbBedsMax" label="Nb de Lits Max" value={`${room?.nbBedsMax}` || ""} errors={errors} onChange={(_, v) => {
      onChange({
        ...room,
        nbBedsMax: v
      })
    }}
    />

  </div>
}