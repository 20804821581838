import React, { useState } from "react"
import { Event } from "model"
import { EventBadge } from "../Badge/EventBadge"
import { EventEdit } from "../Edit/EventEdit"
import { useMutation, useQuery } from "@apollo/react-hooks"
import reservationListEventGql from "graphql/PMS/Reservation/query/reservationListEvent.gql"
import { EventAddByReservation } from "../Add/EventAddByReservation"
import { IconEdit, IconTrash, Modal } from "@zipou/front_tools"
import { ConfirmButton } from "components/common/Navigation/Button/ConfirmButton/ConfirmButton"
import eventDeleteGql from "graphql/PLANNING/Event/mutation/eventDelete.gql"

type EventListForReservationProps = {
  reservationId: string,
  hotelId: string,
}


export const EventListForReservation = ({ reservationId, hotelId }: EventListForReservationProps) => {

  const [focusEdit, updateFocusEdit] = useState<Event>()
  const [focusAdd, updateFocusAdd] = useState<boolean>()


  const [mutate] = useMutation(eventDeleteGql)

  const { data, refetch } = useQuery(reservationListEventGql, {
    variables: {
      reservationId: reservationId,
    },
    skip: !reservationId,
    fetchPolicy: "cache-and-network",
  })


  const onDelete = (eventId: string) => {
    return mutate({
      variables: {
        id: eventId,
      }
    })
      .then(() => {
        refetch()
      })

  }


  const eventList: Event[] = data?.list
  const hasEvent = eventList?.length > 0

  return <div>
    <Modal display={!!focusEdit} title={focusEdit?.Service?.labelFr || ""} onClose={() => updateFocusEdit(null)}>
      <EventEdit eventId={focusEdit?.id} onDone={() => {
        updateFocusEdit(null)
        refetch() // IN case of a deletion
      }} />
    </Modal>
    <Modal display={!!focusAdd} title="Ajouter un service" onClose={() => updateFocusAdd(null)}>
      <EventAddByReservation reservationId={reservationId} hotelId={hotelId} onDone={() => {
        refetch()
        updateFocusAdd(null)
      }} />
    </Modal>
    {!hasEvent && <div className="alert alert-secondary">Aucun service demandé</div>}
    {hasEvent && <table className="table">
      <thead>
        {eventList?.map(event => {
          return <tr className="">
            <td>{event?.Service?.name}</td>
            <td><EventBadge event={event} /></td>
            <td>
              <button className="btn btn-sm btn-dark" onClick={() => updateFocusEdit(event)}>
                <IconEdit />
              </button>
              <ConfirmButton message="Supprimer l'evenement ?" onClick={() => onDelete(event?.id)}>
                {(onClick) => <button className="btn btn-sm btn-danger ml-1" onClick={onClick}>
                  <IconTrash />
                </button>}
              </ConfirmButton>
            </td>
          </tr>
        })}
      </thead>
    </table>}
    <div>
      <button className="btn btn-sm btn-info" onClick={() => updateFocusAdd(true)}>Ajouter un service</button>
    </div>
  </div>
}