import gql from "graphql-tag";
import EventFragmentGql from "../fragment/EventFragment.gql";
import EventPublicFragmentGql from "../fragment/EventPublicFragment.gql";

export default gql`

  ${EventPublicFragmentGql}

  query eventGetByPrestay($id: String!, $publicId: String!, $eventId: String!) {
    event: eventGetByPrestay(id: $id, publicId: $publicId, eventId: $eventId){
      ...EventPublicFragment
    }
  }
`