import { useMutation, useQuery } from "@apollo/react-hooks"
import { tokenContext } from "context/tokenContext"
import { userContext } from "context/User"
import paymentSendGql from "graphql/BILLING/Payment/mutation/paymentSend.gql"
import paymentGetById from "graphql/BILLING/Payment/query/paymentGetById"

import { getRootUrl } from "helpers/Utils"
import { PaymentSendStatusEnum, Token, TokenSendStatusEnum, UserRoleEnum } from "model"
import React, { useContext } from "react"

type TokenSendProps = {
  tokenId: string,
  onDone: () => void,
}


export const TokenSend = ({ tokenId, onDone }: TokenSendProps) => {

  const { data } = useQuery<{ token: Token }>(paymentGetById, {
    variables: {
      id: tokenId
    }
  })

  const [mutate] = useMutation(paymentSendGql)

  const loginToken = useContext(tokenContext)

  const onSend = () => {
    mutate({
      variables: {
        id: tokenId
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch(e => {
        console.log("e", e)
      })
  }


  const user = useContext(userContext)
  const hasDebugRole = user.roles.includes(UserRoleEnum.ROLE_DEBUG)
  const token = data?.token
  const tokenIsSendable = token.sendStatus !== TokenSendStatusEnum.SEND_STATUS_SENT
  const tokenIsReSendable = token.sendStatus === PaymentSendStatusEnum.SEND_STATUS_SENT

  return <div className="card">
    {hasDebugRole && <div className="card-header">Identifiant: {token?.id}</div>}
    {token && <div>

      <div className="card-body">
        <iframe style={{ height: '80vh' }} width='100%' title='confirmation' src={`${getRootUrl()}token/${token?.id}?access_token=${loginToken.token}`}></iframe>
      </div>
      <div className="card-footer">
        {tokenIsSendable && <button className="btn btn-sm btn-success ml-1" onClick={() => onSend()}>Envoyer</button>}
        {tokenIsReSendable && <button className="btn btn-sm btn-warning ml-1" onClick={() => onSend()}>Renvoyer</button>}
        <button className="btn btn-sm btn-danger ml-1" onClick={() => onDone()}>Annuler</button>
      </div>
    </div>}
  </div>


}