import { DateFormat } from "components/common/Format/DateFormat"
import { PriceFormat } from "components/common/Format/PriceFormat"
import React from "react"
import { useTranslation } from "react-i18next"
import { PropositionRowTotal } from "../../Proposition/Row/Display/Total/PropositionRowTotal"
import { PropositionRow } from "model"

export type PriceRateFocusProps = {
  propositionRow: PropositionRow,
}

export const PriceRateFocus = (props: PriceRateFocusProps) => {

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'price_rate' });
  const isFrench = i18n.language === "fr";

  return <div className="">
    <div style={{ paddingTop: 20 }}>
      <PropositionRowTotal propositionRow={props?.propositionRow} showDetails={true} />
    </div>
  </div>
}