import { useQuery } from '@apollo/react-hooks'
import { EventListForReservation } from 'components/front/PLANNING/Event/List/EventListForReservation'
import preStayGet from 'graphql/PMS/Stay/Pre/query/preStayGet'
import { PreStay } from 'model'
import React from 'react'

import "./style.scss"
import { ContactUpdateFieldButton } from 'components/front/PMS/Contact/Button/ContactUpdateFieldButton'
import { ContactMatchCheckin } from 'components/front/PMS/Contact/Match/ContactMatchCheckin'

type PreStayViewProps = {
  preStayId: string,
}

export const PreStayView = (props: PreStayViewProps) => {

  const { data } = useQuery<{ preStay: PreStay }>(preStayGet, {
    variables: {
      id: props?.preStayId,
    },
    skip: !props?.preStayId,
  })

  const preStay: PreStay = data?.preStay
  const content = preStay?.preStayContent
  const checkin = preStay?.Checkin
  const contact = preStay?.Contact

  const hasOptions = content?.optionsSelected

  const hasAddress = !!checkin?.address || !!checkin?.zip || !!checkin?.city || !!checkin?.country

  const hotelId = preStay?.hotelId

  return <div className='prestay-view'>
    <div className='card'>
      <div className='card-header bg-dark text-white'>
        Check-In
      </div>
      <div className='card-body'>

        <ContactMatchCheckin checkin={checkin} contact={contact} />

      </div>
    </div>
    <div className="mt-3" />

    <div className='card'>
      <div className='card-header bg-dark text-white'>
        Services
      </div>
      <div className='card-body'>
        <table className="table table-bordered table-stripped">
          <tbody>
            <tr>
              <th>Arrivée</th>
              <td>
                <span className='badge badge-success'>
                  {content?.arrival?.arrivalHours}:{content?.arrival?.arrivalMinutes}
                </span>
              </td>
            </tr>
            <tr>
              <th>Transfert ?</th>
              <td>{content?.arrival?.transfert ? <span className='badge badge-success'>OUI</span> : <span className='badge badge-dark'>NON</span>}</td>
            </tr>
            <tr>
              <th>Place de Parking</th>
              <td>{content?.arrival?.parking ? <span className='badge badge-success'>OUI</span> : <span className='badge badge-dark'>NON</span>}</td>
            </tr>
            <tr>
              <th>Borne de Recharge</th>
              <td>{content?.arrival?.charging ? <span className='badge badge-success'>OUI</span> : <span className='badge badge-dark'>NON</span>}</td>
            </tr>
            <tr>
              <th>Commentaire</th>
              <td>
                <span className='badge badge-warning'>
                  {content?.arrival?.comment}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div className="mt-3" />

    <div className='card'>
      <div className='card-header bg-dark text-white'>
        Options
      </div>
      <div className='card-body'>
        {!hasOptions && <div className='alert alert-secondary'>Aucune option sélectionnée</div>}
        {hasOptions && <table className='table table-bordered table-striped'>
          <thead>
            <tr className='bg-secondary text-white'>
              <th className='bg-secondary text-white'>Option</th>
              <th className='bg-secondary text-white'>Quantité</th>
            </tr>
          </thead>
          <tbody>
            {content?.optionsSelected?.map(option => {
              return <tr>
                <td>{option?.Option?.labelFr}</td>
                <td>
                  <span className='badge badge-success'>
                    {option?.quantity}
                  </span>
                </td>
              </tr>
            })}

          </tbody>
        </table>}
      </div>
    </div>

    <div className="mt-3" />

    <div className='card'>
      <div className='card-header bg-dark text-white'>
        Services
      </div>
      <div className='card-body'>
        <EventListForReservation reservationId={preStay?.Reservation?.id} hotelId={hotelId} />
      </div>
    </div>
  </div >

}