import { useQuery } from "@apollo/react-hooks"
import bookingEngineBestRateGql from "graphql/PMS/BookingEngine/query/bookingEngineBestRate.gql"
import bookingEngineNbRoomAvailableGql from "graphql/PMS/BookingEngine/query/bookingEngineNbRoomAvailable.gql"
import { PriceRate } from "model"
import React from "react"

type CalendarDayProps = {
  date: Date,
  arrivalDate: Date,
  departuredate: Date,
  day: number,
  nbRoomFreeMinimumForDateToBeAvailable: number,
  hotelId: string,
  showBestRateInCalendar?: boolean,
  onClick: () => void,
  onAvailabilityChange: (date: Date, nbRoomAvailable: number) => void,
}

export const CalendarDay = ({ hotelId, date, day, showBestRateInCalendar, onClick, arrivalDate, departuredate, onAvailabilityChange, nbRoomFreeMinimumForDateToBeAvailable }: CalendarDayProps) => {

  const today = new Date().getTime()

  const time = date?.getTime() || null
  const isPast = today >= time

  const { data: nbRoomData } = useQuery<{ nbRoomAvailable: number }>(bookingEngineNbRoomAvailableGql, {
    variables: {
      hotelId,
      date,
    },
    skip: isPast,
    onCompleted: (data) => {
      onAvailabilityChange(date, data.nbRoomAvailable)
    }
  })

  const nbRoomAvailable = nbRoomData?.nbRoomAvailable || 0
  const hasRoomAvailable = nbRoomAvailable >= nbRoomFreeMinimumForDateToBeAvailable


  const { data: bestRateData } = useQuery<{ bestRate: PriceRate }>(bookingEngineBestRateGql, {
    variables: {
      hotelId,
      date,
    },
    skip: (isPast || !hasRoomAvailable) || !showBestRateInCalendar,
  })


  const hasDay = day > 0
  const bestRateValue = bestRateData?.bestRate?.valueIncTax
  const hasBestRate = bestRateValue > 0

  const isAvailable = !isPast && hasRoomAvailable
  const isSelectable = isAvailable
  const isSelected = arrivalDate <= date && date <= departuredate || arrivalDate?.getTime() === date.getTime()

  const onCellClick = () => {
    if (isSelectable) {
      onClick()
    }
  }

  return <div style={{ cursor: "hand", backgroundColor: isSelected ? "var(--primary-color)" : isAvailable ? "white" : "#CCC", height: "100%", width: "100%" }} onClick={onCellClick} >
    {hasDay && <span>{day}<br /></span>}
    {hasRoomAvailable && <span>{nbRoomAvailable}<br /></span>}
    {hasBestRate && <span>{bestRateValue}<br /></span>}
  </div>

}