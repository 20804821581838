import { useQuery } from "@apollo/react-hooks"
import bookingEngineRoomTypeAvailableGql from "graphql/PMS/BookingEngine/query/bookingEngineRoomTypeAvailable.gql"
import { BookingEngineNbPeopleInput, BookingEngineRoomTypeAvailable } from "model"
import React from "react"
import { BookingEngineRoomTypeDisplay } from "../Display/RoomTypeDisplay"
import { RoomTypeSelectItem } from "./Item/RoomTypeSelectItem"

export type RoomTypeSelectProps = {
  startDate: Date,
  endDate: Date,
  hotelId: string,
  nbPeople: BookingEngineNbPeopleInput,
  onSelect: (roomTypeId: string) => void,
}


export const RoomTypeSelect = ({ startDate, endDate, hotelId, nbPeople, onSelect }: RoomTypeSelectProps) => {

  const { data } = useQuery<{ roomTypeAvailable: BookingEngineRoomTypeAvailable }>(bookingEngineRoomTypeAvailableGql, {
    fetchPolicy: "no-cache",
    variables: {
      hotelId,
      input: {
        startDate,
        endDate,
        nbPeople
      }
    }
  })

  const roomTypeAvailableList = data?.roomTypeAvailable?.roomTypeAvailableList


  return <div>
    {roomTypeAvailableList?.map((roomTypeAvailable, index) => {
      return <div key={`roomType_${index}`} className="pt-3">
        <RoomTypeSelectItem hotelId={hotelId} roomTypeAvailable={roomTypeAvailable} startDate={startDate} endDate={endDate} onSelect={onSelect} />
      </div>
    })}

  </div >
}