import React from "react";
import { CalendarDay } from "./Day/CalendarDay";

type CalendarProps = {
  month: number,
  year: number,
  hotelId: string,
  nbRoomFreeMinimumForDateToBeAvailable: number,
  isFrench?: boolean,
  showBestRateInCalendar?: boolean,
  arrivalDate: Date,
  departureDate: Date,
  onSelectDate: (dateSelected: Date) => void,
  onAvailabilityChange: (date: Date, nbRoomAvailable: number) => void,
}

export const Calendar = ({ year, month, isFrench = true, showBestRateInCalendar = false, onSelectDate, arrivalDate, departureDate, onAvailabilityChange, nbRoomFreeMinimumForDateToBeAvailable, hotelId }: CalendarProps) => {


  // Noms des jours de la semaine (commençant par Lundi)
  const daysOfWeek = isFrench ? ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"] :
    ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  // Nombre de jours dans le mois (month en JS est 1-based dans cette fonction)
  const daysInMonth = new Date(year, month, 0).getDate();

  // Jour de la semaine du 1er du mois (0 = Dimanche, 1 = Lundi, ...)
  // new Date(year, month - 1, 1).getDay() renvoie un index [0..6], 0 = Dimanche
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay();

  // Calcul de l'offset pour démarrer le calendrier le lundi
  // (ex: si firstDayOfMonth est 0 (dimanche), on veut que l'offset soit 6)
  // Cela signifie le nombre de cases vides à placer avant de commencer à numéroter le mois
  const offset = (firstDayOfMonth - 1 + 7) % 7;

  // Calcul du nombre total de cellules (cases) à afficher
  // On ajoute `offset` au nombre de jours du mois, puis on complète pour arriver à un multiple de 7
  let totalCells = offset + daysInMonth;
  if (totalCells % 7 !== 0) {
    totalCells += 7 - (totalCells % 7);
  }

  // Création d'un tableau "cells" de longueur totalCells
  // Chaque case contiendra soit un jour du mois (ex: 1, 2, 3, ...),
  // soit null si c'est une case vide (avant le 1er jour ou après le dernier jour)
  const cells = Array.from({ length: totalCells }, (_, index) => {
    const dayNumber = index + 1 - offset; // On "retire" l'offset
    return dayNumber > 0 && dayNumber <= daysInMonth ? dayNumber : null;
  });

  // On découpe ce tableau en semaines (sous-tableaux de 7 éléments)
  const weeks = [];
  for (let i = 0; i < totalCells; i += 7) {
    weeks.push(cells.slice(i, i + 7));
  }


  return <div>
    <table className="table" style={{ borderCollapse: "collapse", margin: "0" }}>
      <thead>
        <tr>
          {daysOfWeek.map((dayLabel) => (
            <th key={dayLabel} style={{ border: "1px solid #ccc", padding: "0", textAlign: "center", }} >
              {dayLabel}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {weeks.map((week, weekIndex) => (
          <tr key={weekIndex}>
            {week.map((day, dayIndex) => {
              const hasDate = day !== null
              const date = new Date(year, month - 1, day)

              return <td key={dayIndex} style={{ border: "1px solid #aaa", padding: "0", textAlign: "center", height: 30, minWidth: 30 }}>
                {hasDate && <CalendarDay
                  nbRoomFreeMinimumForDateToBeAvailable={nbRoomFreeMinimumForDateToBeAvailable}
                  showBestRateInCalendar={showBestRateInCalendar}
                  day={day}
                  date={date}
                  arrivalDate={arrivalDate}
                  departuredate={departureDate}
                  hotelId={hotelId}
                  onClick={() => onSelectDate(date)}
                  onAvailabilityChange={onAvailabilityChange}
                />}
              </td>
            })}
          </tr>
        ))}
      </tbody>
    </table>
  </div>

}