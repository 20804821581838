import gql from "graphql-tag";
import RoomTypeAvailableItemFragmentGql from "../Item/fragment/RoomTypeAvailableItemFragment.gql";

export default gql`

  ${RoomTypeAvailableItemFragmentGql}

  fragment RoomTypeAvailableFragment on BookingEngineRoomTypeAvailable {
    startDate
    endDate
    roomTypeAvailableList {
      ...RoomTypeAvailableItemFragment
    }
}

`