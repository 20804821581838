import gql from "graphql-tag";

export default gql`

  query roomTypeList($hotelId: String!) {
    roomTypeList(hotelId: $hotelId) {
      id
      type
    }
  }

`