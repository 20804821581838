import { useQuery } from "@apollo/react-hooks";
import { DateTimeFormat } from "components/common/Format/DateTimeFormat";
import { Modal } from "components/common/Navigation/Modal/Modal";
import { EventAddByPreStay } from "components/front/PLANNING/Event/Add/EventAddByPreStay";
import { EventEdit } from "components/front/PLANNING/Event/Edit/EventEdit";
import { EventEditByPreStay } from "components/front/PLANNING/Event/Edit/EventEditByPreStay";
import { EventStatus } from "components/front/PLANNING/Event/Status/EventStatus";
import preStayListEventGql from "graphql/PMS/Stay/Pre/query/preStayListEvent.gql";
import { Hotel, Service, Reservation, PreStay, Event, ServiceTypeEnum } from "model"
import React, { useState } from "react"
import { useTranslation } from "react-i18next";


type ServiceAnswerProps = {
  hotel: Hotel,
  preStay: PreStay,
  reservation: Reservation,
  serviceList: Service[],
  onChange: () => void,
}


export const ServiceAnswer = ({ hotel, preStay, serviceList, onChange }: ServiceAnswerProps) => {

  const [serviceFocus, updateServiceFocus] = useState<Service>()
  const [eventEditFocus, updateEventEditFocus] = useState<Event>()
  const [messageFocus, updateMessageFocus] = useState<boolean>(false)


  const { t, i18n } = useTranslation('translation', { keyPrefix: 'prestay' });
  const isFrench = i18n.language === "fr"

  const themeColor = `#${preStay?.Hotel?.themeColor}`

  const reservation = preStay?.Reservation
  const nbNights = reservation?.nbNight

  const hasServiceToPropose = serviceList?.length > 0

  const { data, refetch } = useQuery<{ list: Event[] }>(preStayListEventGql, {
    variables: {
      publicId: preStay?.publicId,
      id: preStay?.id,
    }
  })

  const eventList: Event[] = data?.list

  return <div>
    {messageFocus && <Modal display={true} onClose={() => updateMessageFocus(null)} themeColor={themeColor} contentClassName={"content-modal-test"}>
      <h2>{t("service_thanks")}</h2>
    </Modal>}
    {serviceFocus && <Modal display={true} onClose={() => updateServiceFocus(null)} themeColor={themeColor} contentClassName={"content-modal-test"}>
      <EventAddByPreStay preStay={preStay} startDate={reservation?.arrivalDate && new Date(reservation?.arrivalDate)} service={serviceFocus} onDone={(eventId) => {
        refetch()
        updateServiceFocus(null)
        updateMessageFocus(true)
        onChange()
      }} />
    </Modal>}
    {eventEditFocus && <Modal display={true} onClose={() => updateEventEditFocus(null)} themeColor={themeColor} contentClassName={"content-modal-test"}>
      <EventEditByPreStay preStay={preStay} eventId={eventEditFocus?.id} onDone={() => {
        refetch()
        updateEventEditFocus(null)
        onChange()
      }} />
    </Modal>}
    {serviceFocus && <Modal display={true} onClose={() => updateServiceFocus(null)} themeColor={themeColor} contentClassName={"content-modal-test"}>
      <EventAddByPreStay preStay={preStay} startDate={reservation?.arrivalDate && new Date(reservation?.arrivalDate)} service={serviceFocus} onDone={(eventId) => {
        refetch()
        updateServiceFocus(null)
        updateMessageFocus(true)
        onChange()
      }} />
    </Modal>}
    {hasServiceToPropose && <div className="">
      <h1>{t("service.title")}</h1>
      <h2>{t("service.desc")}</h2>
      <div className="pt-3">
        {serviceList?.map(service => {

          const eventListForService: Event[] = eventList?.filter(e => e?.serviceId === service?.id)
          const nbEvents = eventListForService?.length
          const hasEvents = nbEvents > 0

          const active = nbEvents < nbNights || true

          return active ? <div className="row p-2" key={`service_${service?.id}`}>

            <div className="d-block d-sm-none col-12 col-md-4 justify-content-center justify-content-sm-start ">
              <img src={service.pictoUrl} className="img-responsive" alt="picto" style={{ maxHeight: 150 }}></img>
            </div>

            <div className="col-12 col-md-8 d-flex">
              <div className="d-flex flex-column justify-content-start w-100 text-right align-items-end">
                <span className='font-weight-bold' style={{ fontSize: "1.1rem" }}>{isFrench ? service?.labelFr : service?.labelEn}</span>
                <span className='pt-1' style={{ color: "#333" }}>{isFrench ? service?.descFr : service?.descEn}</span>
                <div className='pt-1' >
                  <button className={"btn btn-dark btn-sm px-4"} style={{ marginLeft: 5 }} onClick={() => {
                    updateServiceFocus(service)
                  }}>{t("service.book")}</button>
                </div>
                {hasEvents && <div className='pt-3' >
                  {eventListForService?.map(event => {
                    switch (event.Service?.type) {
                      case ServiceTypeEnum.TYPE_RESTAURANT: {
                        return <div className="d-flex flex-column pt-2" key={`event_${event?.id}`}>
                          <div className="font-weight-bold" key={`event_${event?.id}`}>{t("reservation")} - <DateTimeFormat value={new Date(event?.utcDate)} /> - {event.source?.nbPeople} {t("people")}</div>
                          <div>
                            <EventStatus status={event.status} />
                            <span className="ml-2">
                              <span className="badge badge-sm badge-dark" onClick={() => {
                                updateEventEditFocus(event)
                              }}>{t("update")}</span>
                            </span>
                          </div>
                        </div>
                      }
                      case ServiceTypeEnum.TYPE_SPA: {
                        return <div className="d-flex flex-column pt-2" key={`event_${event?.id}`}>
                          <div className="font-weight-bold" key={`event_${event?.id}`}>{t("reservation")} - <DateTimeFormat value={new Date(event?.utcDate)} /> - {event.source?.duration} {t("hours")}</div>
                          <div>
                            <EventStatus status={event.status} />
                            <span className="ml-2">
                              <span className="badge badge-sm badge-dark" onClick={() => {
                                updateEventEditFocus(event)
                              }}>{t("update")}</span>
                            </span>
                          </div>
                        </div>
                      }
                      default: {
                        return <div className="d-flex flex-column pt-2" key={`event_${event?.id}`}>
                           <div className="font-weight-bold" key={`event_${event?.id}`}>{t("reservation")} - <DateTimeFormat value={new Date(event?.utcDate)} /> - {t(event.status)} </div>
                          <div>
                            <EventStatus status={event.status} />
                            <span className="ml-2">
                              <span className="badge badge-sm badge-dark" onClick={() => {
                                updateEventEditFocus(event)
                              }}>{t("update")}</span>
                            </span>
                          </div>
                        </div>
                      }

                      // default: {
                      //   return <div className="font-weight-bold" key={`event_${event?.id}`}>{t("reservation")} - <DateTimeFormat value={new Date(event?.utcDate)} /> - {t(event.status)} </div>
                      // }
                    }
                  })}
                </div>
                }
              </div>
            </div>

            <div className="d-none d-sm-block col-12 col-md-4 justify-content-center justify-content-sm-start ">
              <img src={service.pictoUrl} className="img-responsive" alt="picto" width={"100%"}></img>
            </div>
          </div> : <div />
        })}
      </div>
    </div>}
  </div>
}