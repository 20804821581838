import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/react-hooks";

import { DateFormat } from "components/common/Format/DateFormat";
import propositionCancelSelectionGql from "graphql/PMS/Proposition/mutation/propositionCancelSelection.gql";
import propositionConfirmGql from "graphql/PMS/Proposition/mutation/propositionConfirm.gql";
// import {URL_PUBLIC_PROPOSITION_VALIDATE} from "constant/url";
import propositionSelectRowGql from "graphql/PMS/Proposition/mutation/propositionSelectRow.gql";
import { PropositionPublicValidate } from "./PropositionPublicValidate";
import { PriceRateFocus } from "components/front/PMS/PriceRate/Focus/PriceRateFocus";
import { PropositionRowDisplay } from "components/front/PMS/Proposition/Row/Display/PropositionRowDisplay";

import "./style.scss"
import { Proposition, PropositionStatusEnum } from "model";
import { DateTimeFormat } from "components/common/Format/DateTimeFormat";
import { Modal } from "@zipou/front_tools";
import { hotelContext } from "context/hotelContext";

type PropositionPublicDetailsProps = {
  proposition: Proposition,
}

export const PropositionPublicDetails = (props: PropositionPublicDetailsProps) => {

  const [priceRateFocus, updatePriceRateFocus] = useState<any>(null)
  const [rowFocus, updateRowFocus] = useState<number>(null)

  const { t, i18n } = useTranslation('translation', { keyPrefix: 'proposition' });

  const isFrench = i18n.language === "fr"

  const [mutateSelectRow, { loading }] = useMutation(propositionSelectRowGql)
  const [mutateConfirm] = useMutation(propositionConfirmGql)
  const [mutateCancelSelection] = useMutation(propositionCancelSelectionGql)

  const proposition = props?.proposition;
  const { Hotel, Contact, isExpired, } = proposition || {}

  const onRowSelect = (rowId: string) => {
    return mutateSelectRow({
      variables: {
        id: proposition?.id,
        publicId: proposition?.publicId,
        rowId,
      }
    })
  }

  const onCancelSelection = () => {
    return mutateCancelSelection({
      variables: {
        id: proposition?.id,
        publicId: proposition?.publicId,
      }
    })
  }

  const onConfirm = () => {
    return mutateConfirm({
      variables: {
        id: proposition?.id,
        publicId: proposition?.publicId,
      }
    })
  }

  const hasManyChoices = proposition?.propositionRow?.length > 1
  const isStatusSelected = proposition?.status === "STATUS_SELECTED"
  const isStatusConfirmed = proposition?.status === "STATUS_CONFIRMED"
  const isStatusDraft = proposition?.status === PropositionStatusEnum.STATUS_DRAFT
  const rowsHaveSameDates = proposition?.rowsHaveSameDates

  const propositionFirstRow = proposition?.propositionRow[0]

  const hotel = useContext(hotelContext)
  const illustrationUrl = hotel?.PropositionConfig?.illustrationUrl
  const hasIllustrationUrl = !!illustrationUrl

  return <div className="proposition-public-details">
    {isStatusSelected && <Modal display={true} title={"Validation"} onClose={() => onCancelSelection()}>
      <PropositionPublicValidate proposition={proposition} onCancelSelection={onCancelSelection} onSelect={onConfirm} />
    </Modal>}
    {!!isExpired && <Modal display={true} title={"Expiration"}>
      <div className="alert alert-danger">{t("expired")}</div>
    </Modal>}
    {isStatusDraft && <Modal display={true} title={"Modification en cours"}>
      <div className="alert alert-warning">{t("isDraft")}</div>
    </Modal>}
    {isStatusConfirmed && <Modal display={true}>
      <div className="">
        <h1>{t("Votre réservation est confirmée")}</h1>
      </div>
      <div style={{ paddingTop: 15 }}>
        <p>{t("Une confirmation vous sera directement")}</p>
        <p>{t("Nous vous remercions de votre confiance")}</p>
      </div>
    </Modal>}
    {priceRateFocus && <Modal display={true} title={t("Détails Tarifs")} onClose={() => updatePriceRateFocus(null)}>
      <PriceRateFocus propositionRow={priceRateFocus} />
    </Modal>}
    {rowFocus !== null && <Modal display={true} title={t("details")} onClose={() => updateRowFocus(null)}>
      <PropositionRowDisplay
        showDiscount={proposition?.PropositionConfig?.displayDiscountAmout}
        showCancelCondition={proposition?.PropositionConfig?.displayCancelCondition}
        propositionRow={proposition?.propositionRow[rowFocus]}
        loading={loading}
        onSelect={onRowSelect}
      />
    </Modal>}
    <h1>{t("Votre Proposition")}</h1>
    <div className="card">
      <div className="card-body">
        <table className="table">
          <tbody>
            <tr>
              <td colSpan={2}>
                <span>{Hotel?.name}</span><br />
                <span>{Hotel?.address}</span><br />
                <span>{Hotel?.phoneNumber}</span>
              </td>
            </tr>
            <tr>
              <th>{t("Proposition Id")}</th>
              <td>#{proposition?.publicId}</td>
            </tr>
            <tr>
              <th>{t("Nom")}</th>
              <td>{Contact?.greetingsFormatted}</td>
            </tr>
            <tr>
              <th>{t("Nombre de chambres")}</th>
              <td>{proposition?.nbRooms}</td>
            </tr>
            {rowsHaveSameDates && <><tr>
              <th>{t("Arrivée")}</th>
              <td><DateFormat value={propositionFirstRow?.arrivalDate && new Date(propositionFirstRow?.arrivalDate)} /></td>
            </tr>
              <tr>
                <th>{t("Départ")}</th>
                <td><DateFormat value={propositionFirstRow?.departureDate && new Date(propositionFirstRow?.departureDate)} /></td>
              </tr>
              <tr>
                <th>{t("Nombre de nuits")}</th>
                <td>{propositionFirstRow?.nbNights}</td>
              </tr>
            </>}
            {proposition?.comment && <tr>
              <th>{t("comment")}</th>
              <td>{proposition?.comment}</td>
            </tr>}
            <tr>
              <th>{t("expiration")}</th>
              <td>
                <DateTimeFormat value={proposition?.expirationDate} showUTC={true} />
                <span className="ml-1">{!isFrench ? "French time" : ""}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex">
          <div className="">
            {hasManyChoices && <div className="card-deck">
              {proposition?.propositionRow?.map((row, index: number) => {
                return <div className="card mt-2" style={{ minWidth: 300, maxWidth: 300 }}>
                  <div className="card-header text-white" style={{ backgroundColor: "var(--theme-color)" }}>{t("Proposition {{id}}", { id: index + 1 }).toUpperCase()}</div>
                  <div className="card-body">
                    {!rowsHaveSameDates && <table className="table table-stripped">
                      <tbody>
                        <tr>
                          <th>{t("Arrivée")}</th>
                          <td><DateFormat value={row?.arrivalDate && new Date(row?.arrivalDate)} /></td>
                        </tr>
                        <tr>
                          <th>{t("Départ")}</th>
                          <td><DateFormat value={row?.departureDate && new Date(row?.departureDate)} /></td>
                        </tr>
                        <tr>
                          <th>{t("Nombre de nuits")}</th>
                          <td>{row?.nbNights}</td>
                        </tr>
                      </tbody>
                    </table>}
                    <button className="btn text-white btn-lg" style={{ backgroundColor: "var(--theme-color)", borderColor: "var(--theme-color)" }} onClick={() => updateRowFocus(index)}>{t("review")}</button>
                  </div>
                </div>
              })}
            </div>}
            {!hasManyChoices && <div>
              <PropositionRowDisplay
                showDiscount={proposition?.PropositionConfig?.displayDiscountAmout}
                showCancelCondition={proposition?.PropositionConfig?.displayCancelCondition}
                showDates={false}
                propositionRow={proposition?.propositionRow[0]}
                loading={loading}
                onSelect={onRowSelect}
              />
            </div>}
            {hasIllustrationUrl && <div>
              <img src={illustrationUrl} className="img-responsive" style={{ width: "100%" }} />
            </div>}
          </div>
        </div>
      </div>
    </div>
  </div>
}