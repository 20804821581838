

export const URL_BACKOFFICE = `/backoffice`

export const URL_BACKOFFICE_USER_ADD = `${URL_BACKOFFICE}/user/add`
export const URL_BACKOFFICE_USER_LIST = `${URL_BACKOFFICE}/user/list`
export const URL_BACKOFFICE_USER_EDIT = `${URL_BACKOFFICE}/user/edit/:id`
export const URL_BACKOFFICE_TEMPLATE_EDIT_ROUTE = `${URL_BACKOFFICE}/template/edit/:id`
export const URL_BACKOFFICE_TEMPLATE_ADD_ROUTE = `${URL_BACKOFFICE}/template/add`
export const URL_BACKOFFICE_TEMPLATE_LIST_ROUTE = `${URL_BACKOFFICE}/template/list`

export const URL_CORP_LIST = `${URL_BACKOFFICE}/corp/list`
export const URL_NEWS_LIST = `${URL_BACKOFFICE}/news/list`

export const URL_BACKOFFICE_HOTEL_LIST = `${URL_BACKOFFICE}/hotel/list`
export const URL_BACKOFFICE_HOTEL_EDIT = `${URL_BACKOFFICE}/hotel/edit/:hotelId`
// export const URL_BACKOFFICE_HOTEL_EDIT_FOCUS = `${URL_BACKOFFICE}/hotel/edit/:hotelId/:focus`
export const URL_CONTACT_DEDUP_LIST = `${URL_BACKOFFICE}/contact/dedup/list`


export const URL_BACKOFFICE_BILLING_TOKEN_ADD = `${URL_BACKOFFICE}/token/add`
export const URL_BACKOFFICE_BILLING_TOKEN_LIST = `${URL_BACKOFFICE}/token/list`
export const URL_BACKOFFICE_BILLING_TOKEN_EDIT = `${URL_BACKOFFICE}/token/edit/:id`
export const URL_BACKOFFICE_BILLING_TOKEN_DISPLAY = `${URL_BACKOFFICE}/token/display/:id`

export const URL_CHECKIN_LIST = `${URL_BACKOFFICE}/checkin/list`

export const URL_RESERVATION_GROUP_LIST = `${URL_BACKOFFICE}/reservation/group/list`
export const URL_RESERVATION_GROUP_ADD = `${URL_BACKOFFICE}/reservation/group/add`
export const URL_RESERVATION_GROUP_EDIT = `${URL_BACKOFFICE}/reservation/group/edit/:id`

export const URL_BACKOFFICE_PAYMENT_ADD = `${URL_BACKOFFICE}/payment/add`
export const URL_BACKOFFICE_PAYMENT_EDIT = `${URL_BACKOFFICE}/payment/edit/:paymentId`
export const URL_BACKOFFICE_PAYMENT_LIST = `${URL_BACKOFFICE}/payment/list`

export const URL_PRE_STAY_LIST = `${URL_BACKOFFICE}/prestay/list`
export const URL_PRE_STAY_EDIT = `${URL_BACKOFFICE}/prestay/edit/:id`

export const URL_POST_STAY_LIST = `${URL_BACKOFFICE}/poststay/list`
export const URL_POST_STAY_DISPLAY_ID = `${URL_BACKOFFICE}/poststay/display/:id`

export const URL_POST_STAY_DASHBOARD = `${URL_BACKOFFICE}/poststay/dashboard`
export const URL_POST_STAY_DASHBOARD_ID = `${URL_BACKOFFICE}/poststay/dashboard/:id`

export const URL_NETWORK_CONFIG = `${URL_BACKOFFICE}/network/config`
export const URL_NETWORK_CONFIG_HOTELID = `${URL_BACKOFFICE}/network/config/:hotelId`

// export const URL_VIDEO_CAMERA_LIST = `${URL_BACKOFFICE}/video/camera/list`
// export const URL_VIDEO_CAMERA_DISPLAY = `${URL_BACKOFFICE}/video/camera/display/:id`
// export const URL_VIDEO_CAMERA_LIST_HOTEL_ID = `${URL_BACKOFFICE}/video/camera/:hotelId`

export const URL_VIDEO_DISPLAY = `${URL_BACKOFFICE}/video/display`
export const URL_VIDEO_DISPLAY_ID = `${URL_BACKOFFICE}/video/display/:id`

export const URL_PHONE_CONFIG = `${URL_BACKOFFICE}/phone/config`
export const URL_PHONE_CONFIG_ID = `${URL_BACKOFFICE}/phone/config/:id`
export const URL_PHONE_DIRECTORY = `${URL_BACKOFFICE}/phone/directory`
export const URL_PHONE_DIRECTORY_ID = `${URL_BACKOFFICE}/phone/directory/:id`
export const URL_PHONE_CALL_LIST = `${URL_BACKOFFICE}/phone/call/list`
export const URL_PHONE_CALL_LIST_ID = `${URL_BACKOFFICE}/phone/call/list/:id`
export const URL_PHONE_CALL_LIST_PAGE = `${URL_BACKOFFICE}/phone/call/list/:id/:page`
export const URL_PHONE_CALL_EDIT = `${URL_BACKOFFICE}/phone/call/edit/:id`
export const URL_PHONE_CALL_EDIT_PAGE = `${URL_BACKOFFICE}/phone/call/edit/:id/:page`


export const URL_CONFIRMATION_LIST = `${URL_BACKOFFICE}/confirmation/list`
export const URL_CONFIRMATION_ANSWER_ROUTE = `${URL_BACKOFFICE}/confirmation/:confirmationId/answer`

export const URL_PROPOSITION_PUSH = `${URL_BACKOFFICE}/proposition/push/:id`
export const URL_PROPOSITION_EDIT = `${URL_BACKOFFICE}/proposition/edit/:id`
export const URL_PROPOSITION_DISPLAY = `${URL_BACKOFFICE}/proposition/display/:id`
export const URL_PROPOSITION_ADD = `${URL_BACKOFFICE}/proposition/add`
export const URL_PROPOSITION_LIST = `${URL_BACKOFFICE}/proposition/list`

export const URL_PLANNING_VIEW = `${URL_BACKOFFICE}/planning/view/:id`
export const URL_PLANNING_ADD = `${URL_BACKOFFICE}/planning/add`
export const URL_PLANNING_EDIT = `${URL_BACKOFFICE}/planning/edit/:id`
export const URL_PLANNING_LIST = `${URL_BACKOFFICE}/planning/list`
export const URL_PLANNING_EVENT_LIST = `${URL_BACKOFFICE}/planning/event/list`
export const URL_PLANNING_EVENT_EDIT = `${URL_BACKOFFICE}/planning/event/edit/:id`

export const URL_FRONT = "/"
export const URL_SEARCH = "/search"
export const URL_SEARCH_QUERY = "/search/:query"
export const URL_GRAPHQL = "/graphiql"

export const URL_USER_CONFIG_LIST = "/user/config/list"

export const URL_RESERVATION_DETAILS = `/reservation/details/:id`
export const URL_RESERVATION_SEARCH = `/reservation/search`

export const URL_CUSTOMER_DETAIL = `/customer/details/:id`
export const URL_CUSTOMER_LIST = `/customer/list`
export const URL_CUSTOMER_LIST_PARAMS = `/customer/list/:page/:orderField/:orderDirection`

export const URL_CRM_SEGMENTATION_ADD = `/com/segmentation/add`
export const URL_COM_CONTACT_LIST_EDIT = `/com/segmentation/edit/:id`
export const URL_CRM_SEGMENTATION_LIST = `/com/segmentation/list`

export const URL_PUBLIC = "/p"

export const URL_PUBLIC_FRONT = `${URL_PUBLIC}/front`

export const URL_PUBLIC_BOOKING_ENGINE = `${URL_PUBLIC}/book`


export const URL_PUBLIC_CONFIRMATION_DETAILS = `${URL_PUBLIC}/confirmation/:id/:publicId/:hotelId`

export const URL_VIDEO_PANEL_DISPLAY = `${URL_PUBLIC}/video/panel/:panelId/:tokenId/:token`

export const URL_PUBLIC_PROPOSITION_DETAILS = `${URL_PUBLIC}/proposition/:id/:publicId`

export const URL_PUBLIC_DEVICE = `${URL_PUBLIC}/device/:id/:token`

export const URL_PUBLIC_PRE_STAY_ANSWER = `${URL_PUBLIC}/prestay/:id/:publicId`
export const URL_PUBLIC_POST_STAY_ANSWER = `${URL_PUBLIC}/poststay/:id/:publicId`


export const URL_PUBLIC_RESERVATION_DETAILS = `${URL_PUBLIC}/details/:id/:contactId/:hotelId`


export const URL_PUBLIC_TOKEN_DISPLAY = `${URL_PUBLIC}/token/:id/:salt`

export const URL_PUBLIC_PAYMENT_3DSECURE = `${URL_PUBLIC}/payment/:id/:salt`
export const URL_PUBLIC_PAYMENT_3DSECURE_ID = `${URL_PUBLIC}/payment/:id`
