import React, { useContext, useEffect } from "react";

import { userContext } from "../context/User"

import { useMutation, useQuery } from "@apollo/react-hooks";

import userProfileQuery from "graphql/APP/User/query/userProfile"

// import userLoginByGoogleTokenMutation from "graphql/APP/User/mutation/userLoginByGoogleToken";
import userLoginByTokenMutation from "graphql/APP/User/mutation/userLoginByToken"
import { LoginToken, User } from "model";
import { LoginForm } from "./front/APP/User/Login/LoginForm";
import { IconLoading, Modal } from "@zipou/front_tools";
import { tokenContext } from "context/tokenContext";


export enum AuthTypeEnum {
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
}

export type AuthWrapperProps = {
  children: any,
  authType: AuthTypeEnum,
}

export const AuthWrapper = ({ children, authType }: AuthWrapperProps) => {

  const { data, loading, refetch } = useQuery<{ userProfile: User }>(userProfileQuery, {
    notifyOnNetworkStatusChange: true,
  });


  const [loginByTokenMutate] = useMutation<{ userLoginByToken: LoginToken }>(userLoginByTokenMutation)

  const { updateToken } = useContext(tokenContext)

  const handleLoginByToken = async (id: string, loginToken: string) => {

    const { data } = await loginByTokenMutate({
      variables: {
        id,
        token: loginToken,
      }
    })

    updateCredentials(data.userLoginByToken)

    return true

  }

  const updateCredentials = (loginToken: LoginToken) => {
    console.log("UPDATING CREDENTIALS", loginToken)
    updateToken(loginToken)
  }

  const { userProfile } = data || {}
  const isAuthenticated = !!userProfile

  useEffect(() => {
    if (!userProfile) {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get('id')
      const token = urlParams.get('token')
      if (token && id) {
        console.log("Trying to login via token")
        handleLoginByToken(id, token)
          .then(() => {
            console.log("Refetching")
            refetch()
          })
      }
    }
  }, [userProfile])


  switch (authType) {

    case AuthTypeEnum.REQUIRED: {
      return (isAuthenticated) ? <userContext.Provider value={userProfile}>
        <div className="login-ok">
          {children}
        </div>

      </userContext.Provider> : <div>
        <Modal display={loading} size={"sm"}>
          <IconLoading style={{ fontSize: 72 }} />
        </Modal>
        <Modal display={!loading}>
          <LoginForm onSuccess={updateCredentials} />
        </Modal>
        {/* {props?.children} */}

      </div>
    }

    case AuthTypeEnum.OPTIONAL: {
      return <userContext.Provider value={userProfile}>
        {children}
      </userContext.Provider>

    }
    default: {
      return <Modal display={true}>
        <LoginForm onSuccess={updateCredentials} />
      </Modal>
    }
  }
}